export type CertificateType = {
  certDescription: string;
  certImg: string;
  certName: string;
};
export type ReviewType = {
  desc: string;
  name: string;
};

export type provinceType = {
  name: string
}

export enum AllPosition {
  all= "",
  chuyengia = "Chuyên gia",
  thay = "Thầy",
}
export type ProfileType = {
  id: string;
  nameImage: string;
  avatar: string;
  fullName: string;
  branchName: string;
  yearOfBirth: string;
  position?: AllPosition;
  branchPhoneNumber: string;
  personalPhoneNumber: string;
  priorityLevel: number;
  branchEmail: string;
  location: {
    address: string;
    coordinates: [];
  };
  achievement?: string;
  exp?: string;
  majors?: string[];
  certs?: CertificateType[];
  reviews?: ReviewType[];
  desc?: string;
  link?: string;
  type?: string[];
  key: string;
  title: string;
};
