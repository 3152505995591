import {
  Flex,
  FlexProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import { CertificateType } from "../../constant/DataProfiles/DataTypes";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {} & FlexProps;

const SlideCertificate = (itemCard: any, { ...props }: Props) => {
  const {
    isMobile,
    isSamsungZFold5,
    isTablet,
    isTabletOrLaptop,
    isMobileOrTablet,
    isBigScreen,
  } = useResponsive();
  const name = itemCard?.itemCard?.fullName;
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] =
    useState<CertificateType | null>(null);

  const openModal = (certificate: CertificateType) => {
    setSelectedCertificate(certificate);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedCertificate(null);
  };

  const handleSlideChange = (swiper: any) => {
    setActiveSlide(swiper.realIndex);
  };
  const certificates =
    itemCard?.itemCard?.certs.filter((i: any) => i.certImg) || [];
  return (
    <Container direction="column" position="relative">
      <LazyLoadImage
        style={{
          height: isMobile
            ? "400px"
            : isMobileOrTablet
            ? "700px"
            : isTabletOrLaptop
            ? "750px"
            : "100%",
        }}
        src={isTabletOrLaptop ? IMAGES.bgCertificateMb : IMAGES.bgCertificate}
        alt=""
      />
      <LazyLoadImage
        style={{
          padding: "10px",
          width: isMobileOrTablet ? "90%" : "50%",
          top: "5%",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        src={IMAGES.lineCertificate}
        alt=""
      />
      <Flex
        position="absolute"
        top= {isMobile ? "15%" : "13%"}
        alignContent="space-between"
        left="50%"
        height="100%"
        transform="translate(-50%, -50%)"
        {...props}
        justifyContent="center"
        alignItems="center"
        padding={isMobileOrTablet ? "0 10px" : "0 50px"}
        w="100%"
      >

      <Text
        fontSize={isMobile ? 24 : 32}
        fontWeight={700}
        lineHeight="30px"
        style={{ color: "#FFF6CF" }}
      >
        DANH SÁCH CHỨNG CHỈ
      </Text>
      </Flex>
      <Flex
        position="absolute"
        top="50%"
        alignContent="space-between"
        left="50%"
        height="100%"
        transform="translate(-50%, -50%)"
        {...props}
        justifyContent="center"
        alignItems="center"
        padding={isMobileOrTablet ? "0 10px" : "0 50px"}
        w="100%"
      >
        {certificates.length > 0 && (
          <Swiper
            onSlideChange={handleSlideChange}
            // effect="coverflow"
            grabCursor={false}
            // centeredSlides={true}
            initialSlide={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            slidesPerView={isMobileOrTablet ? 1 : isTabletOrLaptop ? 2 : 2}
            spaceBetween={20}
            // coverflowEffect={{
            //   rotate: 0,
            //   stretch: 0,
            //   depth: 10,
            //   modifier: 2.5,
            //   slideShadows: false,
            // }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Navigation, EffectCoverflow]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={(swiper: any) => {
              if (typeof swiper.params.navigation === "object") {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.update();
              }
            }}
            style={{
              // width: isMobileOrTablet
              //   ? "90%"
              //   : isTabletOrLaptop
              //   ? "70%"
              //   : "90%",
              cursor: "default !important",
            }}
          >
            {certificates.length > 0 && (
              <>
                {certificates?.map((certificate: any, index: any) => (
                  <SwiperSlide
                    key={index}
                    className="swiper-slide custom-slide"
                  >
                    {certificate.certImg ? (
                      <Stack style={{ position: "relative" }} alignItems="center">
                        <LazyLoadImage
                          src={certificate.certImg}
                          alt={certificate.certName}
                          style={{ width: isTablet ? "80%"  : "100%", height: "100%" }}
                        />
                      </Stack>
                    ) : null}
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>
        )}
      </Flex>
      {!isMobile && certificates.length >= 2 && (
        <>
          <Stack
            ref={navigationPrevRef}
            sx={{
              position: "absolute",
              top: "50%",
              left: isMobile ? "2%" : "4%",
              zIndex: 10,
              cursor: "pointer",
              transform: "translateY(-50%)",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "1px 1px 6px 0px #FFECBE",
            }}
          >
            <LazyLoadImage src={IMAGES.back} alt="" />
          </Stack>
          <Stack
            ref={navigationNextRef}
            transition="all 235ms ease-in-out"
            cursor="pointer"
            sx={{
              position: "absolute",
              top: "50%",
              right: isMobile ? "2%" : "4%",
              zIndex: 10,
              cursor: "pointer",
              transform: "translateY(-50%)",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "1px 1px 6px 0px #FFECBE",
            }}
          >
            <LazyLoadImage src={IMAGES.next} alt="" />
          </Stack>
        </>
      )}
      <LazyLoadImage
        style={{
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
          padding: "10px",
          width: isMobileOrTablet ? "90%" : "50%",
        }}
        src={IMAGES.line2Certificate}
        alt=""
      />
    </Container>
  );
};

export default SlideCertificate;
