import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";

const BackgroundLoader = ({ imageUrl, children, setIsLoaded, isLoaded } : {imageUrl : any, children: any,  setIsLoaded: any , isLoaded: any}) => {
  
    useEffect(() => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setIsLoaded(true);
    }, [imageUrl]);
  
    return (
      <Stack 
      className="backgroundProfile"
      style={{backgroundImage: `url(${imageUrl})`}}
      >
        {isLoaded ? children : null}
      </Stack>
    );
  };
  export default BackgroundLoader