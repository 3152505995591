import { Flex, Stack, Text } from "@chakra-ui/react";

import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import { TextDefault, Wrapper } from "./style";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Footer: React.FC = () => {
  const { isMobile, isTabletOrLaptop, isBigScreen, isTablet } = useResponsive();

  const column1 = [
    {
      img: IMAGES.cityIcon,
      desc: (
        <TextDefault>CÔNG TY TNHH THƯƠNG MẠI PHONG THỦY ĐẠI NAM</TextDefault>
      ),
      link: "",
    },
    {
      img: IMAGES.taxIcon,
      desc: <TextDefault>0202 221 629</TextDefault>,
      link: "tel:0202 221 629",
    },
    {
      img: IMAGES.locationIcon,
      desc: (
        <Stack w={isTabletOrLaptop ? "100%" : "550px"}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.google.com/maps?q=Paris+19-15+Vinhomes+Imperia,+Phường+Thượng+Lý,+Quận+Hồng+Bàng,+Hải+Phòng"
          >
            <TextDefault>
              Trụ sở: Paris 19-15 Vinhomes Imperia, phường Thượng Lý, quận Hồng
              Bàng, Hải Phòng
            </TextDefault>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.google.com/maps?q=Số+25-27+Ngõ+208,+Trần+Cung,+Cổ+Nhuế,+Bắc+Từ+Liêm,+Hà+Nội"
          >
            <TextDefault>
              VP Hà Nội: 114 Trần Vỹ, Phường Mai Dịch, Quận Cầu Giấy,Tp. Hà Nội
            </TextDefault>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.google.com/maps?q=XheroZone+Center+60C+Trường+Sơn,+Phường+2,+Tân+Bình,+Tp.+Hồ+Chí+Minh"
          >
            <TextDefault>
              VP Hồ Chí Minh: XheroZone Center 60C Trường Sơn, phường 2, Tân
              Bình, TP. Hồ Chí Minh
            </TextDefault>
          </a>
        </Stack>
      ),
      link: "",
    },
  ];
  const column2 = [
    {
      img: IMAGES.webIcon,
      desc: "www.phongthuydainam.vn",
      link: "https://phongthuydainam.vn/",
    },
    {
      img: IMAGES.mailIcon,
      desc: "dainam@xherozone.com",
      link: "mailto:dainam@xherozone.com",
    },
    {
      img: IMAGES.phoneIcon,
      desc: "1900 989 919",
      link: "tel:1900 989 919",
    },
  ];
  return (
    <Stack spacing={0}>
      <Wrapper>
        <Container>
          <Stack w="full" p="53px 0" spacing={0}>
            <Flex
              direction={isTabletOrLaptop ? "column-reverse" : "row"}
              justifyContent="space-between"
              w="full"
            >
              <Flex
                alignItems={
                  isMobile ? "center" : isTablet ? "flex-end" : "flex-start"
                }
                direction="row"
                justifyContent="space-between"
                w={isTabletOrLaptop ? "100%" : "auto"}
                gap={isMobile ? "10px" : "30px"}
              >
                <Stack
                  spacing={0}
                  gap={isMobile ? 0 : "10px"}
                  display={isTabletOrLaptop ? "flex" : "none"}
                  mt="10px"
                >
                  {column2.map((item, index) => (
                    <Flex
                      alignItems="center"
                      key={index}
                      gap={isMobile ? "5px" : "20px"}
                    >
                      <LazyLoadImage
                        src={item.img}
                        width="50px"
                        height="50px"
                        alt=""
                      />
                      <a href={item.link}>
                        <TextDefault>{item.desc}</TextDefault>
                      </a>
                    </Flex>
                  ))}
                </Stack>
                <LazyLoadImage
                  src={IMAGES.QR}
                  width={
                    isMobile
                      ? "110px"
                      : isTabletOrLaptop
                      ? "150px"
                      : isBigScreen
                      ? "200px"
                      : "250px"
                  }
                  style={{
                    borderRadius: isMobile
                      ? "12px"
                      : isTablet
                      ? "16px"
                      : "24px",
                    marginTop: "10px",
                  }}
                  height={
                    isMobile
                      ? "110px"
                      : isTabletOrLaptop
                      ? "150px"
                      : isBigScreen
                      ? "200px"
                      : "250px"
                  }
                  alt=""
                />
              </Flex>
              <Stack spacing={0}>
                {column1.map((item, index) => (
                  <Flex
                    alignItems={isMobile ? "center" : "center"}
                    key={index}
                    gap={isMobile ? "5px" : "20px"}
                  >
                    <LazyLoadImage
                      style={{ width: "50px", height: "50px" }}
                      src={item.img}
                      width="30px"
                      height="30px"
                      alt=""
                      loading="lazy"
                    />
                    <a href={item.link}>
                      <TextDefault>{item.desc}</TextDefault>
                    </a>
                  </Flex>
                ))}
              </Stack>
              <Stack
                display={isTabletOrLaptop ? "none" : "flex"}
                mt="10px"
                spacing={0}
              >
                {column2.map((item, index) => (
                  <Flex
                    alignItems={isMobile ? "start" : "center"}
                    key={index}
                    gap={isMobile ? "5px" : "20px"}
                  >
                    <LazyLoadImage
                      src={item.img}
                      width={isMobile ? "30px" : "50px"}
                      height={isMobile ? "30px" : "50px"}
                      alt=""
                      loading="lazy"
                    />
                    <a href={item.link}>
                      <TextDefault>{item.desc}</TextDefault>
                    </a>
                  </Flex>
                ))}
              </Stack>
            </Flex>
          </Stack>
        </Container>
      </Wrapper>
    </Stack>
  );
};
export default Footer;
