const COLOR = {
  text: "#FFE146",
  lineInfo: "#FFF385",
  textDeco: "#FFF6CF",
  white: "white",
  black: "black",
  whiteBold: "#FFFFFF",
  gray: "#797979",
  inActive: "#b2afad",
  hover: "#FFCF00",
  expertText: "#FFF0C9",
  paginationText: "#161616B2",
  bgPagination: "linear-gradient(90deg, #C7986F -1612.99%, #9C5100 -97.06%)",
  titleProfile: "#7C460C",
  bgContact: "#661800",
  shadow: "#00000040",
  placeholder: "#929292",
  input: "#72130F",
  activeTab: "#B17636",
  tab: "#B1B1B1",
  descProfile: "#353535",
  expert: "#F3F2F2",
  inputBorder: "#4B280180",
  expert1: "linear-gradient(90deg, #460D0B 0%, rgba(159, 54, 9, 0) 100%)",
  expert2: "linear-gradient(90deg, #FFB500 0%, rgba(255, 181, 0, 0) 100%)",
  expert1Text: "#FFF0C9",
  expert2Text: "#5E0D0A",
  footerPrivate: "#667085",
  footerText: "#595959",
  bgNav: "#00000033",
  bgBreadcrumb: "#FDF9F0",
  bgBranch: "#FDF2D2",
  borderTab: "#D0D5DD",
  textBranch: "#212121",
  activeTabBranch: "#72130F",
  textContent: "#42290A",
  textLinear: "linear-gradient(139.56deg, #FDD96D 9.18%, #F2B525 53.88%, #F8BE24 95.97%)",
  expert3:
    "linear-gradient(90deg, rgba(85, 85, 85, 0.35) 0%, rgba(85, 85, 85, 0) 100%)",
  divider:
    "linear-gradient(90deg,rgba(114, 19, 15, 0) 0%, rgba(114, 19, 15, 0.67) 50%, rgba(114, 19, 15, 0) 100%)",
};

export default COLOR;
