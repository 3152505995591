import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ScrollableTextContainerProps {
  children: ReactNode;
  height: string;
  isMobile: any
}
const ScrollableTextContainer: React.FC<ScrollableTextContainerProps> = ({
  children,
  height,
  isMobile
}) => (
  
  <Box
  sx={{
    height: height,
    overflowY: "auto",
    width: "100%",
    paddingRight: "10px",
    boxSizing: "content-box",
    scrollbarWidth: "thin",
    scrollbarColor: "#7C460C #E2E8F0",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E2E8F0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#7C460C",
      borderRadius: "8px",
      height: "20px",
      border: "2px solid #E2E8F0",
      minHeight: "20px",
    },
  }}
  >
    {children}
  </Box>
);

export default ScrollableTextContainer;
