import { Flex, FlexProps } from "@chakra-ui/react";
import useResponsive from "../../hooks/useResponsive";

const Container = ({ children, ...props }: FlexProps) => {
  const { isBigScreen } = useResponsive();
  return (
    <Flex
      w={!isBigScreen ? "90vw" : "95vw"}
      m={"0 auto"}
      maxWidth="1240px"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Container;
