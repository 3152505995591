import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BranchCard,
  BranchListBox,
  ButtonContact,
  Container,
  ContentBranch,
  LabelSlide,
  ModalDeriction,
  PopupContainer,
  PopupContent,
  PopupTitle,
  TabBranch,
  TextButton,
  TextCard,
  TextContact,
  TextSearch,
  TextSlide,
  Wrapper,
  WrapperModal,
} from "./style";
import {
  Flex,
  Grid,
  GridItem,
  Input,
  keyframes,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import axios from "axios";
import LoadingPage from "../../component/LoadingPage";
import useResponsive from "../../hooks/useResponsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { debounce } from "lodash";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import FooterAllProfiles from "../../component/FooterAllProfiles";
const apiKeyGoogle = process.env.REACT_APP_GOOGLE_API_KEY;
const BranchList = () => {
  const dataOption = {
    all: "Toàn Quốc",
    bac: "Miền Bắc",
    trung: "Miền Trung",
    nam: "Miền Nam",
  };
  const dataSlide = [
    {
      img: IMAGES.iconSupport,
      title: "Đồng Hành Hỗ Trợ",
      label: "Trước, trong và sau dịch vụ",
    },
    {
      img: IMAGES.iconFeedback,
      title: "Khoa Học và Thực Tiễn ",
      label: "Trong phương pháp tư vấn ",
    },
    {
      img: IMAGES.iconShield,
      title: "Cam Kết Bảo Mật",
      label: "Thông tin khách hàng",
    },
    {
      img: IMAGES.iconGuaranteed,
      title: "Bảo Hành 1 Năm ",
      label: "Tư vấn lại miễn phí",
    },
  ];
  type BranchList = {
    _id: string;
    email: string;
    imageUrl: string;
    branchImages: [string];
    name: string;
    phoneNumber: string;
    address: string;
    timeOpen: string;
    timeClose: string;
    decs: string;
    location: {
      address: string;
    };
  };
  type Market = {
    id: string;
    position: { lat: any; lng: any };
    title: any;
    snippet: any;
    img: string;
    icon: {
      url: string;
      scaledSize: any;
      origin: any;
      anchor: any;
    };
  };
  const [listBranchs, setLIstBranchs] = useState<BranchList[]>([]);
  const {
    isMobile,
    isMobileOrTablet,
    isTabletOrLaptop,
    isBigScreen,
    isTablet,
  } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [location, setLocation] = useState("all");
  const [indexActive, setIndexActive] = useState(0);
  const [branchDetails, setBranchDetails] = useState<BranchList>(Object);
  const [markers, setMarkers] = useState<Market[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [slide, setSlide] = useState(false);
  const renderBullet = (index: any, className: any) => {
    return `<div class="${className}"></div>`;
  };
  const handleClickSlide = () => {
    setSlide(!slide);
  };
  const handleSearch = () => {
    getListBranch(location);
  };
  const defaultMapOptions = {
    center: { lat: 14.0583, lng: 108.2772 },
    zoom: 6,
  };
  const initializeMap = useCallback(() => {
    const mapElement = document.getElementById("map");
    if (mapElement && !map) {
      const mapInstance = new window.google.maps.Map(
        mapElement,
        defaultMapOptions
      );
      setMap(mapInstance);
    }
  }, [map]);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);
  const dataDemo = [IMAGES.imageDemo, IMAGES.imageDemo, IMAGES.imageDemo];
  const getListBranch = async (values: string) => {
    setIsLoading(true);
    setMarkers([]);

    try {
      const rs = await axios.get(
        `${process.env.REACT_APP_API_URL}/branches?name=${branchName}&key=${values}`
      );
      const branches = rs.data.data.data;
      const markersForBranchPerBatch = 1; // Số lượng marker thêm vào mỗi lần
      const delayMilliseconds = 200; // Thời gian trễ giữa mỗi lần thêm

      let index = 0;
      setLIstBranchs(branches);

      const addMarkers = () => {
        if (index >= branches.length) {
          return;
        }

        const newMarkers: Market[] = [];
        for (
          let i = 0;
          i < markersForBranchPerBatch && index < branches.length;
          i++, index++
        ) {
          const branch = branches[index];
          const position = {
            lat: branch.location?.coordinates?.[0] || 0,
            lng: branch.location?.coordinates?.[1] || 0,
          };

          if (window.google) {
            newMarkers.push({
              id: `marker${index}`,
              img:
                branch.branchImages?.length > 0
                  ? branch.branchImages[0]
                  : IMAGES.imageDemo,
              position,
              title: branch.name || "",
              snippet: branch.location?.address || "",
              icon: {
                url: IMAGES.iconMarket,
                scaledSize: new window.google.maps.Size(100, 120),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(25, 50),
              },
            });
          }
        }

        setMarkers((prevMarkers) => [...prevMarkers, ...newMarkers]);
        setTimeout(addMarkers, delayMilliseconds);
      };
      addMarkers();
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching branches:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSearchLocation = async (values: string, index: any) => {
    setActiveTab(index);
    setLocation(values);
    setBranchName(branchName);
    await getListBranch(values);
  };
  const getBranchDetails = async (id: string) => {
    // setIsLoading(true);
    try {
      const rs = await axios.get(
        `${process.env.REACT_APP_API_URL}/branches/${id}`
      );
      const branch = rs.data.data;
      setBranchDetails(branch);
    } catch (err) {}
  };
  const option = Object.entries(dataOption);
  const handleOpenModal = async (values: string) => {
    // setBranchDetails(values);
    await getBranchDetails(values);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setIndexActive(0)
  };
  useEffect(() => {
    getListBranch(location);
  }, []);
  const onLoad = useCallback(
    function callback(map: any) {
      setMap(map);
    },
    [markers]
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  const colorChange = keyframes`
0% {
  color: #161616;
}
    25% {
  color: #72130FAB;
}
50% {
  color: #EE433C;
}
100% {
  color: #ED0E05;
}
`;
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
  };

  const MarkerPopup = ({
    title,
    content,
    img,
  }: {
    title: any;
    content: any;
    img: string;
  }) => (
    <PopupContainer>
      <Stack gap="10px">
        <img src={img} width="100%" />
        <PopupTitle>{title}</PopupTitle>
        <PopupContent>{content}</PopupContent>
      </Stack>
    </PopupContainer>
  );
  const [activeMarker, setActiveMarker] = useState(null);
  const handleClick = (markerId: any) => {
    setActiveMarker(markerId);
  };
  const handelEnter = (e: any) => {
    if(e.code === "Enter") {
      handleSearch()
    }
  }
  return (
    <>
      {isLoading && markers.length < listBranchs.length ? (
        <LoadingPage />
      ) : (
        <>
          {branchDetails._id !== undefined && (
            <Modal
              size="sm"
              isCentered={true}
              isOpen={isOpen}
              onClose={onClose}
              motionPreset="slideInBottom"
            >
              <ModalOverlay />
              <ModalContent
                sx={{
                  maxWidth: 900,
                  width: "100%",
                  height: "90vh",
                  padding: isMobile ? "16px" : "32px",
                  margin: "auto",
                  backgroundImage: `url(${IMAGES.bgPopup})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",

                  overflow: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#4F4F4F transparent",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "100px",
                  },
                  "&::-webkit-scrollbar-button": {
                    display: "none",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#E2E8F0",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#7C460C",
                    borderRadius: "8px",
                    height: "20px",
                    border: "2px solid #E2E8F0",
                    minHeight: "20px",
                  },
                }}
              >
                {/* <ModalCloseButton /> */}
                <ModalBody>
                  <Flex direction={isMobile ? "column" : "row"} gap="32px">
                    <img
                      style={{ borderRadius: "12px", maxHeight: "500px" }}
                      width={isMobile ? "100%" : "75%"}
                      src={
                        branchDetails?.branchImages?.length > 0
                          ? branchDetails?.branchImages[indexActive]
                          : IMAGES.imageDemo
                      }
                    />

                    <Stack
                      direction={isMobile ? "row" : "column"}
                      gap={isMobile ? "16px" : "32px"}
                      w="30%"
                      overflow={"auto"}
                      height={"500px"}
                      sx={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "#4F4F4F transparent",
                        "&::-webkit-scrollbar": {
                          width: "8px",
                          borderRadius: "100px",
                        },
                        "&::-webkit-scrollbar-button": {
                          display: "none",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#E2E8F0",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#7C460C",
                          borderRadius: "8px",
                          height: "20px",
                          border: "2px solid #E2E8F0",
                          minHeight: "20px",
                        },
                      }}
                    >
                      <Stack width={"100%"} height={"100%"}>
                        {branchDetails?.branchImages.map((i, r) => (
                          <div
                            style={{ position: "relative" }}
                            key={r}
                            onClick={() => setIndexActive(r)}
                          >
                            <img width="100%" src={i} />
                            <div
                              style={{
                                cursor: "pointer",
                                display: indexActive !== r ? "block" : "none",
                                position: "absolute",
                                background: "#D9D9D9",
                                opacity: 0.5,
                                width: "100%",
                                height: "100%",
                                top: 0,
                              }}
                            ></div>
                          </div>
                        ))}
                      </Stack>
                    </Stack>
                  </Flex>
                  <Stack mt="20px" mb="40px" gap="10px">
                    <Stack></Stack>
                    <TextCard className="hoverText">
                      {branchDetails.name}
                    </TextCard>
                    <Flex
                      gap="5px"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <img src={IMAGES.branchPlace} width={20} height={20} />
                      <ContentBranch className="hoverLabel">
                        {branchDetails.location?.address}
                      </ContentBranch>
                    </Flex>
                    <Flex gap="5px" alignItems="center">
                      <img src={IMAGES.branchPhone} width={20} height={20} />
                      <ContentBranch className="hoverLabel">
                        {branchDetails.phoneNumber}
                      </ContentBranch>
                    </Flex>
                    <Flex gap="5px" alignItems="center">
                      <img src={IMAGES.iconCalender} width={20} height={20} />
                      <ContentBranch className="hoverLabel">
                        {branchDetails.timeOpen || "8:00"} -{" "}
                        {branchDetails.timeClose || "22:00"}
                      </ContentBranch>
                    </Flex>
                  </Stack>
                  <ModalDeriction>{branchDetails.decs}</ModalDeriction>
                  <Stack mt="30px" gap="20px">
                    <TextCard>Liên hệ Chuyên gia</TextCard>
                    <Flex
                      gap="20px"
                      padding="20px"
                      direction={isMobile ? "column" : "row"}
                      background={COLOR.whiteBold}
                      alignItems="center"
                      borderRadius={"10px"}
                    >
                      <img src="../xhero/imgThayManh.png" />
                      <Stack gap="10px">
                        <Text
                          fontSize={20}
                          fontWeight={600}
                          marginBottom="0"
                          style={{ color: "#464646" }}
                        >
                          Phong Thủy Sư
                        </Text>
                        <Text
                          fontSize={isMobile ? 20 : 30}
                          fontWeight={700}
                          marginBottom="0"
                          style={{ color: "#000000" }}
                        >
                          Nguyễn Trọng Mạnh
                        </Text>
                        <Text
                          fontSize={20}
                          fontWeight={400}
                          marginBottom="0"
                          style={{ color: "#231F20B2" }}
                        >
                          Thầy Nguyễn Trọng Mạnh - Người sáng lập Phong Thuỷ Đại
                          Nam là Phong Thuỷ Sư có kiến thức uyên thâm về "Phong
                          thuỷ khoa học" mang lại giá trị thiết thực cho cộng
                          đồng. Thầy luôn nhiệt huyết, truyền lửa cho toàn hệ
                          sinh thái Phong Thuỷ Đại Nam.
                        </Text>
                        <a
                          href="https://phongthuydainam.vn/lien-he/"
                          style={{
                            textDecoration: "none",
                            width: isMobile
                              ? "200px"
                              : isMobileOrTablet
                              ? "210px"
                              : "50%",
                          }}
                          target="_blank"
                        >
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            cursor="pointer"
                            // onClick={onOpen}
                          >
                            <img
                              style={{
                                height: "50px",
                              }}
                              src={IMAGES.contactBtt}
                              alt=""
                            />

                            <Text
                              m={0}
                              position="absolute"
                              top="50%"
                              left="50%"
                              _hover={{ color: COLOR.input }}
                              transform="translate(-50%,-50%)"
                              fontWeight={700}
                              animation={`${colorChange} 5s linear infinite`}
                              fontSize={
                                isMobile
                                  ? "18px"
                                  : isTabletOrLaptop
                                  ? "18px"
                                  : "22px"
                              }
                              textAlign="center"
                              color={COLOR.black}
                              textTransform="uppercase"
                              width="100%"
                            >
                              Liên hệ
                            </Text>
                          </Stack>
                        </a>
                      </Stack>
                    </Flex>
                  </Stack>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
          <Wrapper>
            <Container>
              <Flex
                direction={isMobile || isMobileOrTablet ? "column" : "row"}
                gap="30px"
                justifyContent="space-between"
                height={isMobile || isMobileOrTablet ? "auto" : 50}
              >
                <Flex style={{ overflow: "auto" }}>
                  <Stack direction="row">
                    {option.map((item, index) => (
                      <TabBranch
                        key={index}
                        onClick={() =>
                          handleSearchLocation(item[0].toString(), index)
                        }
                        className={activeTab === index ? "activeTab" : ""}
                      >
                        {item[1]}
                      </TabBranch>
                    ))}
                  </Stack>
                </Flex>
                <Flex
                  gap="30px"
                  direction={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  width={isMobile || isMobileOrTablet ? "100%" : "60%"}
                >
                  <Flex w={isMobile ? "100%" : isTablet ? "70%" : "70%"}>
                    <Input
                      style={{ background: COLOR.whiteBold, height: "auto" }}
                      placeholder="Tìm kiếm chi nhánh..."
                      onChange={(e) => setBranchName(e.target.value)}
                      value={branchName}
                      onKeyDown={(e) => handelEnter(e)}
                    />
                    <div
                      onClick={handleSearch}
                      style={{
                        height: isMobileOrTablet ? "auto" : "50px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={IMAGES.iconSearch}
                        style={{
                          height: isMobileOrTablet ? "100%" : "50px",
                          borderRadius: "10px",
                          width: "50px",
                        }}
                      />
                    </div>
                  </Flex>
                  <a href="tel:0812838338" style={{ textDecoration: "none" }}>
                    <ButtonContact>
                      <Flex alignItems="center" gap="10px">
                        <img src={IMAGES.iconContact} width={28} height={28} />
                        <Stack gap="5px">
                          <TextContact>Góp ý kiếu nại</TextContact>
                          <TextContact style={{ fontSize: 16 }}>
                            0812 83 83 38
                          </TextContact>
                        </Stack>
                      </Flex>
                    </ButtonContact>
                  </a>
                </Flex>
              </Flex>
              <Flex
                position="relative"
                margin="32px 0"
                justifyContent="space-between"
              >
                {isMobile ||
                  (isTablet && (
                    <div
                      style={{ position: "absolute", right: 0, zIndex: 99 }}
                      onClick={handleClickSlide}
                    >
                      <img src={IMAGES.imageMap} width={64} height={64} />
                    </div>
                  ))}
                <BranchListBox>
                  <div
                    style={{
                      height: 700,
                      paddingBottom: isMobile ? "0" : "32px",
                      overflow: "auto",
                      scrollbarWidth: "thin",
                    }}
                    className="scrollWidth"
                  >
                    {listBranchs.length === 0 ? (
                      <TextSearch>
                        Không tìm thấy kết quả phù hợp. Vui lòng thử từ khóa
                        khác
                      </TextSearch>
                    ) : (
                      <Grid
                        templateColumns={
                          isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"
                        }
                        gap={2}
                      >
                        {listBranchs.map((item, index) => (
                          <GridItem w="100%" key={index}>
                            <BranchCard>
                              <Stack
                                padding="20px 0"
                                w="100%"
                                justifyContent="space-between"
                                h="100%"
                                gap="20px"
                              >
                                <img
                                  style={{
                                    margin: "0 auto",
                                    maxWidth: "340px",
                                    height: "220px",
                                  }}
                                  width="100%"
                                  src={
                                    item.branchImages?.length > 0
                                      ? item.branchImages[0]
                                      : IMAGES.imageDemo
                                  }
                                />
                                <Stack
                                  gap="10px"
                                  height={190}
                                  justifyContent="flex-start"
                                >
                                  <TextCard className="hoverText">
                                    {item.name}
                                  </TextCard>
                                  <Flex
                                    gap="5px"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <div className="branchPlace">
                                      <div
                                        style={{ width: 24, height: 24 }}
                                      ></div>
                                    </div>
                                    <ContentBranch className="hoverLabel">
                                      {item.location.address}
                                    </ContentBranch>
                                  </Flex>
                                  <Flex gap="5px" alignItems="center">
                                    <div className="branchPhone">
                                      <div
                                        style={{ width: 24, height: 24 }}
                                      ></div>
                                    </div>
                                    <ContentBranch className="hoverLabel">
                                      {item.phoneNumber}
                                    </ContentBranch>
                                  </Flex>
                                </Stack>
                                <Flex
                                  gap="20px"
                                  w="100%"
                                  justifyContent="space-between"
                                >
                                  <Stack
                                    onClick={() => handleOpenModal(item._id)}
                                    style={{
                                      cursor: "pointer",
                                      backgroundImage: `url(${IMAGES.buttonBranch})`,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "100% 100%",
                                      justifyContent: "center",
                                      width: "50%",
                                    }}
                                  >
                                    <TextButton className="hoverTextButton">
                                      Xem chi tiết
                                    </TextButton>
                                  </Stack>
                                  <Stack
                                    style={{
                                      cursor: "pointer",

                                      backgroundImage: `url(${IMAGES.ButtonBg})`,
                                      backgroundRepeat: "no-repeat",
                                      padding: "10px",
                                      backgroundSize: "100% 100%",
                                      justifyContent: "center",
                                      width: "50%",
                                    }}
                                  >
                                    <TextButton>Xem chỉ đường</TextButton>
                                  </Stack>
                                </Flex>
                              </Stack>
                            </BranchCard>
                          </GridItem>
                        ))}
                      </Grid>
                    )}
                  </div>
                </BranchListBox>
                <Stack
                  className={`slideDiv ${slide === true ? "active" : ""}`}
                  style={{
                    // display: isMobile && slide === false ? "none" : "flex",
                    backgroundImage: `url(${IMAGES.bgBranchRight})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <LoadScript
                    googleMapsApiKey={apiKeyGoogle || ""} // Replace with your API key
                  >
                    <GoogleMap
                      id="map"
                      mapContainerStyle={mapContainerStyle}
                      center={defaultMapOptions.center}
                      zoom={6}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      options={{
                        scrollwheel: true,
                        rotateControl: true,
                        zoomControl: false,
                        mapTypeId: "hybrid",
                        disableDefaultUI: true,
                        gestureHandling: "greedy",
                      }}
                    >
                      {markers.map((marker) => (
                        <Marker
                          key={marker.id}
                          position={marker.position}
                          title={marker.title}
                          // label={marker.snippet}
                          icon={marker.icon}
                          onClick={() => handleClick(marker.id)}
                        >
                          {activeMarker === marker.id && (
                            <InfoWindow
                              position={marker.position}
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <MarkerPopup
                                title={marker.title}
                                content={marker.snippet}
                                img={marker.img}
                              />
                            </InfoWindow>
                          )}
                        </Marker>
                      ))}
                    </GoogleMap>
                  </LoadScript>
                </Stack>
              </Flex>
            </Container>
          </Wrapper>
          <>
            {isMobile ? (
              <Flex
                style={{
                  backgroundImage: `url(${IMAGES.imgBranch})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  justifyContent: "center",
                  width: "100%",
                  padding: "30px 0",
                }}
              >
                <Swiper
                  grabCursor={false}
                  initialSlide={1}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  className="my-swiper"
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: renderBullet,
                  }}
                  loop={true}
                  slidesPerView={1}
                  spaceBetween={20}
                  modules={[Autoplay, Pagination]}
                  style={{
                    cursor: "default !important",
                  }}
                >
                  {dataSlide.map((i, r) => (
                    <SwiperSlide key={r}>
                      <Stack gap="10px" key={r} alignItems="center">
                        <img width={44} height={44} src={i.img} />
                        <TextSlide>{i.title}</TextSlide>
                        <LabelSlide>{i.label}</LabelSlide>
                      </Stack>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Flex>
            ) : (
              <Flex
                style={{
                  backgroundImage: `url(${IMAGES.imgBranch})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  justifyContent: "center",
                  width: "100%",
                  padding: "30px ",
                  gap: "40px",
                }}
              >
                {dataSlide.map((i, r) => (
                  <Flex gap="50px" alignItems="center" key={r}>
                    <Stack gap="10px" key={r} alignItems="center">
                      <img width={70} height={70} src={i.img} />
                      <TextSlide>{i.title}</TextSlide>
                      <LabelSlide>{i.label}</LabelSlide>
                    </Stack>
                    <div
                      style={{
                        width: "1px",
                        height: 48,
                        background: COLOR.whiteBold,
                      }}
                    ></div>
                  </Flex>
                ))}
              </Flex>
            )}
          </>
          <FooterAllProfiles />
        </>
      )}
    </>
  );
};
export default BranchList;
