import { Flex, keyframes, Stack, Text, Tooltip } from "@chakra-ui/react";
import Container from "../../../component/Container";
import COLOR from "../../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Section2 from "../Section2";
import SwiperReview from "../../../component/Swiper";
import SlideCertificate from "../../../component/SlideCertificate";
import APP_ROUTES from "../../../constant/CONFIG_ROUTES/app_routes";
import { TextBranchName, TextContact, Wrapper } from "./style";

const Section1 = (itemCard: any) => {
  const {
    isMobile,
    isTablet,
    isSamsungZFold5,
    isMobileOrTablet,
    isTabletOrLaptop,
    isBigScreen,
  } = useResponsive();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedBg, setIsLoadedBg] = useState(false);
  const starts = [
    {
      img: IMAGES.rating,
    },
    {
      img: IMAGES.rating,
    },
    {
      img: IMAGES.rating,
    },
    {
      img: IMAGES.rating,
    },
    {
      img: IMAGES.rating,
    },
  ];
  const colorChange = keyframes`
  0% {
    color: #161616;
  }
      25% {
    color: #72130FAB;
  }
  50% {
    color: #EE433C;
  }
  100% {
    color: #ED0E05;
  }
`;
  useEffect(() => {
    const img = new Image();
    img.src = IMAGES.bgTop;
    img.onload = () => setIsLoaded(true);
  }, [IMAGES.bgTop]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    // Bắt đầu đo thời gian
    const startTime = performance.now();

    const fetchData = async () => {
      // Giả sử đây là hoạt động tải dữ liệu thực sự hoặc nội dung khác
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Giả lập thời gian tải dữ liệu

      // Kết thúc đo thời gian
      const endTime = performance.now();

      // Tính toán thời gian tải
      setLoadTime(endTime - startTime);

      setIsLoading(false); // Sau khi tất cả nội dung đã được tải xong
    };

    fetchData();
  }, []);
  return (
    <>
      <Stack
        className={`background-image-top ${isLoaded ? "loaded" : ""}`}
        spacing={0}
      >
        <Wrapper>
          {isLoaded && (
            <Container mt="30px">
              <Stack spacing={0}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="full"
                >
                  <a rel="noreferrer" href={APP_ROUTES.ALL}>
                    <LazyLoadImage
                      style={{
                        cursor: "pointer",
                        width: isMobile
                          ? "80px"
                          : isMobileOrTablet
                          ? "100px"
                          : "200px",
                      }}
                      src={IMAGES.logoXHero}
                      alt=""
                    />
                  </a>
                  <a rel="noreferrer" href={APP_ROUTES.ALL}>
                    <LazyLoadImage
                      style={{
                        cursor: "pointer",
                        width: isMobile
                          ? "80px"
                          : isMobileOrTablet
                          ? "100px"
                          : "200px",
                      }}
                      src={IMAGES.logoDaiNam}
                      alt=""
                    />
                  </a>
                </Flex>

                <Stack spacing={0} mt={isMobile ? "20px" : "10px"}>
                  <Flex
                    padding={
                      isMobile
                        ? "12px 0 0 0px"
                        : isSamsungZFold5
                        ? "0px"
                        : isMobileOrTablet
                        ? "0 0 0 50px"
                        : "10px 0 0 90px"
                    }
                    justifyContent="space-between"
                    alignItems={isMobile ? "center" : "start"}
                    width="full"
                    maxW="1240px"
                  >
                    <LazyLoadImage
                      src={itemCard?.itemCard?.avatar}
                      alt=""
                      loading="lazy"
                      style={{
                        marginLeft: isMobile ? "2%" : 0,
                        width: isMobile
                          ? "120px"
                          : isMobileOrTablet
                          ? "130px"
                          : isTabletOrLaptop
                          ? "170px"
                          : "300px",
                        zIndex: 0,
                      }}
                    />

                    <LazyLoadImage
                      style={{
                        position: "absolute",
                        top: isMobile ? "4%" : isTablet ? "4%" : "8%",
                        left: isMobile ? "54%" : "51%",
                        transform: "translate(-50%,-50%)",
                      }}
                      loading="lazy"
                      src={IMAGES.titleTop}
                      alt=""
                      width={
                        isMobile
                          ? "160px"
                          : isMobileOrTablet
                          ? "270px"
                          : isTabletOrLaptop
                          ? "300px"
                          : "460px"
                      }
                    />

                    <LazyLoadImage
                      src={IMAGES.phongthuy}
                      className={`imgSamSung ${isLoaded ? "loaded" : ""}`}
                      style={{
                        width: isMobile
                          ? "110px"
                          : isMobileOrTablet
                          ? "180px"
                          : isTabletOrLaptop
                          ? "240px"
                          : "400px",
                      }}
                      alt=""
                    />
                  </Flex>

                  <Container zIndex={1}>
                    <Stack position="relative" spacing={0}>
                      <LazyLoadImage
                        src={isMobile ? IMAGES.bgInfoMobile : IMAGES.bgInfo}
                        className={`background-image-top ${
                          isLoadedBg ? "loaded" : ""
                        }`}
                        alt=""
                        loading="lazy"
                        style={{
                          width: isMobile
                            ? "100%"
                            : isSamsungZFold5
                            ? "800px"
                            : "100%",
                          height: isMobile
                            ? "auto"
                            : isMobileOrTablet
                            ? "290px"
                            : "auto",
                          display: "block",
                          objectFit: isMobile
                            ? "contain"
                            : isMobileOrTablet
                            ? "fill"
                            : "contain",
                        }}
                        onLoad={() => setIsLoadedBg(true)}
                      />
                      {isLoaded && (
                        <Stack
                          gap={
                            isMobileOrTablet
                              ? "5px"
                              : isTabletOrLaptop
                              ? "10px"
                              : "15px"
                          }
                          position="absolute"
                          top={isMobile ? "6%" : isMobileOrTablet ? "5%" : "5%"}
                          left={isMobile ? 0 : isMobileOrTablet ? "3%" : "0"}
                          justifyContent={
                            isTabletOrLaptop ? "start" : "space-between"
                          }
                          width={
                            isMobileOrTablet
                              ? "100%"
                              : isSamsungZFold5
                              ? "500px"
                              : "calc(100% - 0%)"
                          }
                          padding={
                            isMobile
                              ? "10px 30px"
                              : isSamsungZFold5
                              ? "20px 10px"
                              : isMobileOrTablet
                              ? "10px 0"
                              : "10px 7%"
                          }
                        >
                          <Flex
                            justifyContent={
                              isMobileOrTablet ? "start" : "space-between"
                            }
                            direction={isMobile ? "column" : "row"}
                            width="100%"
                            gap="15px"
                          >
                            <LazyLoadImage
                              loading="lazy"
                              src={itemCard?.itemCard?.nameImage}
                              style={{
                                width: isMobile
                                  ? "85%"
                                  : isMobileOrTablet
                                  ? "240px"
                                  : isTabletOrLaptop
                                  ? "300px"
                                  : "450px",
                                height: isTabletOrLaptop ? "100%" : "110px",
                                maxWidth: "100%",
                              }}
                              alt=""
                            />
                            <Flex gap="5px">
                              {starts.map((item, index) => (
                                <Flex
                                  alignItems="center"
                                  gap="20px"
                                  key={index}
                                  flexWrap="wrap"
                                >
                                  <LazyLoadImage
                                    loading="lazy"
                                    style={{
                                      width: isMobileOrTablet
                                        ? "15px"
                                        : isTabletOrLaptop
                                        ? "20px"
                                        : "100%",
                                    }}
                                    src={item.img}
                                    alt=""
                                  />
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                          <Stack
                            gap={
                              isMobile
                                ? "5px"
                                : isMobileOrTablet
                                ? 5
                                : isTabletOrLaptop
                                ? 2
                                : "10px"
                            }
                          >
                            <Stack
                              sx={{ backgroundColor: COLOR.lineInfo }}
                              height="2px"
                              width={isMobileOrTablet ? "250px" : "500px"}
                              padding="0 10px"
                              margin={
                                isMobile
                                  ? "10px 0"
                                  : isMobileOrTablet
                                  ? 0
                                  : isTabletOrLaptop
                                  ? "10px 0"
                                  : "10px  0"
                              }
                            />
                            <Stack
                              gap={
                                isMobile
                                  ? "5px"
                                  : isMobileOrTablet
                                  ? 5
                                  : isTabletOrLaptop
                                  ? 2
                                  : "10px"
                              }
                              direction={isMobile ? "column" : "row"}
                              justifyContent={
                                isMobileOrTablet
                                  ? "flex-start"
                                  : "space-between"
                              }
                              height={isMobile ? "auto" : "40px"}
                            >
                              {isMobile ? (
                                <>
                                  <TextBranchName>
                                    Phong Thủy Đại Nam
                                  </TextBranchName>
                                  <TextBranchName>
                                    Chi nhánh {itemCard?.itemCard?.branchName}
                                  </TextBranchName>
                                </>
                              ) : (
                                <TextBranchName>
                                  Phong Thủy Đại Nam - Chi nhánh{" "}
                                  {itemCard?.itemCard?.branchName}
                                </TextBranchName>
                              )}
                              <a
                                href="https://phongthuydainam.vn/lien-he/"
                                target="_blank"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: isMobileOrTablet ? "45px" : "65px",
                                  marginTop: isMobile ? 5 : 0,
                                }}
                              >
                                <Flex
                                  gap="5px"
                                  className="animated-button"
                                  // animation={`${shake} 5s ease-in-out infinite`}

                                  sx={{
                                    backgroundImage: `url(${IMAGES.bgButton})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "100% 100%",
                                    padding: "10px",
                                    justifyContent: "center",
                                    marginBottom: isMobile
                                      ? "0"
                                      : isSamsungZFold5
                                      ? "15px"
                                      : 0,
                                    alignItems: "center",
                                    width: isMobile
                                      ? 180
                                      : isTabletOrLaptop
                                      ? 200
                                      : isBigScreen
                                      ? 280
                                      : 260,
                                    height: isMobile ? "40px" : "100%",
                                  }}
                                >
                                  <Flex
                                    sx={{
                                      fontFamily: "K2D",
                                      fontSize: isMobile
                                        ? 16
                                        : isTabletOrLaptop
                                        ? 18
                                        : 24,
                                      fontWeight: 800,
                                      lineHeight: isMobileOrTablet
                                        ? "20px"
                                        : "38px",
                                      cursor: "pointer",
                                      transition: "all 0.2 ease-in-out",
                                    }}
                                    animation={`${colorChange} 8s linear infinite`}
                                    _hover={{
                                      transform: "translate3d(0, 2px, 0)",
                                      color: COLOR.input,
                                    }}
                                  >
                                    LIÊN HỆ TƯ VẤN
                                  </Flex>
                                </Flex>
                              </a>
                            </Stack>

                            <Stack
                              mt={
                                isMobile
                                  ? "0"
                                  : isTabletOrLaptop
                                  ? "-20px"
                                  : "-10px"
                              }
                              spacing={0}
                              gap={
                                isMobile ? "5px" : isMobileOrTablet ? 0 : "5px"
                              }
                            >
                              <Flex
                                direction={isMobile ? "column" : "row"}
                                width="100%"
                                gap={isMobile ? "5px" : "150px"}
                                padding={isMobileOrTablet ? "1px 0" : "5px 0"}
                              >
                                <Flex gap="10px" alignItems="center">
                                  <LazyLoadImage
                                    style={{
                                      width: isMobile ? "32px" : "40px",
                                    }}
                                    loading="lazy"
                                    src={IMAGES.mailIcon}
                                    alt=""
                                  />
                                  <a
                                    href={`mailto:${itemCard?.itemCard?.email}`}
                                  >
                                    <TextContact>
                                      {itemCard?.itemCard?.branchEmail}
                                    </TextContact>
                                  </a>
                                </Flex>
                              </Flex>
                              <Flex gap="10px" alignItems="center">
                                <img
                                  loading="lazy"
                                  src={IMAGES.locationIcon}
                                  style={{ width: isMobile ? "32px" : "40px" }}
                                  alt=""
                                />
                                <TextContact>
                                  {itemCard?.itemCard?.location.address}
                                </TextContact>
                              </Flex>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Container>
                </Stack>
              </Stack>
            </Container>
          )}
        </Wrapper>
      </Stack>
      {itemCard.itemCard && !isLoading && (
        <Section2 itemCard={itemCard.itemCard} />
      )}
      {itemCard.itemCard && !isLoading && (
        <SlideCertificate itemCard={itemCard.itemCard} />
      )}
      {itemCard.itemCard && !isLoading && (
        <SwiperReview itemCard={itemCard.itemCard} />
      )}
    </>
  );
};

export default Section1;
