import { Flex, Stack, Text } from "@chakra-ui/react";

import { useState } from "react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import ContainerAllProfiles from "../ContainerAllProfiles";
import APP_ROUTES from "../../constant/CONFIG_ROUTES/app_routes";
import { LazyLoadImage } from "react-lazy-load-image-component";
const FooterAllProfiles: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();
  const [tab, setTab] = useState<number | null>(1);
  const data = [
    {
      id: 1,
      title: "THÔNG TIN CHUNG",
      desc: [
        {
          title: "Liên Hệ",
          link: "https://phongthuydainam.vn/lien-he/",
        },
        {
          title: "Điều khoản sử dụng",
          link: "https://phongthuydainam.vn/dieu-khoan-su-dung/",
        },
        {
          title: "Hướng dẫn mua hàng",
          link: "https://phongthuydainam.vn/chinh-sach-thanh-toan/",
        },
        {
          title: "Chính sách bảo mật thông tin",
          link: "https://phongthuydainam.vn/chinh-sach-bao-mat/",
        },
        {
          title: "Chính sách thanh toán",
          link: "https://phongthuydainam.vn/chinh-sach-thanh-toan/",
        },
        {
          title: "Chính sách khiếu nại",
          link: "https://phongthuydainam.vn/chinh-sach-khieu-nai/",
        },
        {
          title: "Chính sách vận chuyển",
          link: "https://phongthuydainam.vn/chinh-sach-van-chuyen/",
        },
        {
          title: "Bảo hành & hoàn tiền",
          link: "https://phongthuydainam.vn/dieu-khoan-su-dung/",
        },
      ],
    },
    {
      id: 2,
      title: "ĐÀO TẠO",

      desc: [
        {
          title: "Học Phong Thủy Cơ Bản",
          link: "https://phongthuydainam.vn/danh-muc/dao-tao-phong-thuy/",
        },
        {
          title: "Học Phong Thủy Nâng Cao",
          link: "https://phongthuydainam.vn/danh-muc/dao-tao-phong-thuy/",
        },
        {
          title: "Trạch Cát Tại Gia",
          link: "https://phongthuydainam.vn/san-pham/trach-cat-tai-gia/",
        },
        {
          title: "Gia Trạch Phúc Khí",
          link: "https://phongthuydainam.vn/san-pham/gia-trach-phuc-khi-thuan-the-hung-long/",
        },
        {
          title: "Chuyển Giao Phong thủy",
          link: "https://phongthuydainam.vn/san-pham/chuyen-giao-chuyen-gia-tu-van-phong-thuy-duong-trach/",
        },
        {
          title: "Xem ngày Vượng Cát",
          link: "https://phongthuydainam.vn/san-pham/xem-ngay-cat-loi/",
        },
        {
          title: "Kỳ Môn Độn Giáp",
          link: "https://phongthuydainam.vn/san-pham/ky-mon-don-giap-a1/",
        },
      ],
    },
    {
      id: 3,
      title: "DỊCH VỤ",
      desc: [
        {
          title: "Phong Thủy Nhà Ở",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-thiet-ke-phong-thuy-nha-o-biet-thu-dai-nam/",
        },
        {
          title: "Phong Thủy Nhà thờ",
          link: "https://phongthuydainam.vn/tu-van-thiet-ke-phong-thuy-am-trach-dai-nam/",
        },
        {
          title: "Phong Thủy Âm Trạch",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-chon-dat-phong-thuy-am-trach-dai-nam/",
        },
        {
          title: "Phong Thủy Doanh nghiệp",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-phong-thuy-kinh-doanh-thuong-mai-dai-nam/",
        },
        {
          title: "Phong Thủy Nhà xưởng, Nhà máy",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-thiet-ke-phong-thuy-nha-xuong-nha-may-dai-nam/",
        },
        {
          title: "Tư vấn Kỳ Môn Độn Giáp",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-ky-mon-don-giap/",
        },

        {
          title: "Tư vấn Nghi thức, Nghi lễ",
          link: "https://phongthuydainam.vn/dich-vu-tu-van-nghi-thuc-nghi-le-dai-nam/",
        },
      ],
    },
    {
      id: 4,
      title: "QUY TRÌNH",
      desc: [
        {
          title: "Quy trình tư vấn",
          link: "https://www.facebook.com/phongthuydainam.vn6868/posts/pfbid0UpXpM1WmQYhm6VHdoLMsLEHX6YoMw2miehXJ1FDyjWKA1E4L7QwEGtkeLXJHEU7Cl",
        },
        {
          title: "Quy trình đào tạo",
          link: "https://www.facebook.com/phongthuydainam.vn6868/posts/pfbid0ud3k5nXFjHwx9wgR5YYyA6dQvoAzxdDAiDME4ZqUuWiaMDZESbSKZCjGE1SnpwiHl",
        },
        {
          title: "Quy trình sản xuất thạch thủy bình",
          link: "https://phongthuydainam.vn/",
        },
      ],
    },
  ];
  const info = [
    {
      title: "Liên Hệ",
      link: "https://phongthuydainam.vn/lien-he/",
    },
    {
      title: "Điều khoản sử dụng",
      link: "https://phongthuydainam.vn/dieu-khoan-su-dung/",
    },
    {
      title: "Hướng dẫn mua hàng",
      link: "https://phongthuydainam.vn/chinh-sach-thanh-toan/",
    },
    {
      title: "Chính sách bảo mật thông tin",
      link: "https://phongthuydainam.vn/chinh-sach-bao-mat/",
    },
    {
      title: "Chính sách thanh toán",
      link: "https://phongthuydainam.vn/chinh-sach-thanh-toan/",
    },
    {
      title: "Chính sách khiếu nại",
      link: "https://phongthuydainam.vn/chinh-sach-khieu-nai/",
    },
    {
      title: "Chính sách vận chuyển",
      link: "https://phongthuydainam.vn/chinh-sach-van-chuyen/",
    },
    {
      title: "Bảo hành & hoàn tiền",
      link: "https://phongthuydainam.vn/dieu-khoan-su-dung/",
    },
  ];
  const training = [
    {
      title: "Học Phong Thủy Cơ Bản",
      link: "https://phongthuydainam.vn/danh-muc/dao-tao-phong-thuy/",
    },
    {
      title: "Học Phong Thủy Nâng Cao",
      link: "https://phongthuydainam.vn/danh-muc/dao-tao-phong-thuy/",
    },
    {
      title: "Trạch Cát Tại Gia",
      link: "https://phongthuydainam.vn/san-pham/trach-cat-tai-gia/",
    },
    {
      title: "Gia Trạch Phúc Khí",
      link: "https://phongthuydainam.vn/san-pham/gia-trach-phuc-khi-thuan-the-hung-long/",
    },
    {
      title: "Chuyển Giao Phong thủy",
      link: "https://phongthuydainam.vn/san-pham/chuyen-giao-chuyen-gia-tu-van-phong-thuy-duong-trach/",
    },
    {
      title: "Xem ngày Vượng Cát",
      link: "https://phongthuydainam.vn/san-pham/xem-ngay-cat-loi/",
    },
    {
      title: "Kỳ Môn Độn Giáp",
      link: "https://phongthuydainam.vn/san-pham/ky-mon-don-giap-a1/",
    },
  ];
  const service = [
    {
      title: "Phong Thủy Nhà ở",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-thiet-ke-phong-thuy-nha-o-biet-thu-dai-nam/",
    },
    {
      title: "Phong Thủy Nhà thờ",
      link: "https://phongthuydainam.vn/tu-van-thiet-ke-phong-thuy-am-trach-dai-nam/",
    },
    {
      title: "Phong Thủy Âm Trạch",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-chon-dat-phong-thuy-am-trach-dai-nam/",
    },
    {
      title: "Phong Thủy Doanh nghiệp",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-phong-thuy-kinh-doanh-thuong-mai-dai-nam/",
    },
    {
      title: "Phong Thủy Nhà xưởng, Nhà máy",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-thiet-ke-phong-thuy-nha-xuong-nha-may-dai-nam/",
    },
    {
      title: "Tư vấn Kỳ Môn Độn Giáp",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-ky-mon-don-giap/",
    },

    {
      title: "Tư vấn Nghi thức, Nghi lễ",
      link: "https://phongthuydainam.vn/dich-vu-tu-van-nghi-thuc-nghi-le-dai-nam/",
    },
  ];
  const procedure = [
    {
      title: "Quy trình tư vấn",
      link: "https://www.facebook.com/phongthuydainam.vn6868/posts/pfbid0UpXpM1WmQYhm6VHdoLMsLEHX6YoMw2miehXJ1FDyjWKA1E4L7QwEGtkeLXJHEU7Cl",
    },
    {
      title: "Quy trình đào tạo",
      link: "https://www.facebook.com/phongthuydainam.vn6868/posts/pfbid0ud3k5nXFjHwx9wgR5YYyA6dQvoAzxdDAiDME4ZqUuWiaMDZESbSKZCjGE1SnpwiHl",
    },
    {
      title: "Quy trình sản xuất thạch thủy bình",
      link: "https://phongthuydainam.vn/",
    },
  ];
  const social = [
    {
      img: IMAGES.tiktokIcon,
      link: "https://www.tiktok.com/@phongthuydainamofficial?_t=8nA6JnHqtjs&_r=1",
    },
    {
      img: IMAGES.zaloIcon,
      link: "https://zalo.me/0788686898",
    },
    {
      img: IMAGES.facebookIcon,
      link: "https://www.facebook.com/phongthuydainam.vn6868",
    },
    {
      img: IMAGES.youtubeIcon,
      link: "https://www.youtube.com/@phongthuydainam",
    },
  ];
  const title = [
    {
      link: "",
      title: "@2015 - 2024 Công Ty TNHH thương mại Phong Thủy Đại Nam ",
    },
    {
      link: "",
      title:
        "Số ĐKKD 0202221629 Cấp ngày 15/11/2023 Nơi cấp Sở Kế hoạch & Đầu tư thành phố Hải Phòng ",
    },
    {
      title:
        "Địa chỉ: PARIS 19-15 VINHOMES IMPERIA, PHƯỜNG THƯỢNG LÝ, QUẬN HỒNG BÀNG, HẢI PHÒNG",
      link: "https://www.google.com/maps/search/Paris+19-15+Vinhomes+Imperia,+Ph%C6%B0%E1%BB%9Dng+Th%C6%B0%E1%BB%A3ng+L%C3%BD,+Qu%E1%BA%ADn+H%E1%BB%93ng+B%C3%A0ng,+H%E1%BA%A3i+Ph%C3%B2ng/@34.8778329,54.5185873,3z/data=!3m1!4b1?entry=ttu",
    },
    {
      title: "Hotline: 1900 989 919",
      link: "tel: 1900 989 919",
    },
    {
      title: "Email: DAINAM@XHEROZONE.COM",
      link: "mailto:DAINAM@XHEROZONE.COM",
    },
  ];
  return (
    <Stack spacing={0}>
      {window.location.pathname !== APP_ROUTES.BRANCHLIST && 
      <Flex
        sx={{
          backgroundImage: `url(${IMAGES.bgFooter1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        p="40px 0"
      >
        
        <ContainerAllProfiles
          justifyContent={isMobileOrTablet ? "center" : "space-between"}
          direction={isMobileOrTablet ? "column" : "row"}
          alignItems="center"
          gap="10px"
        >
          <Flex gap="0px" alignItems={isMobileOrTablet ? "center" : "center"} justifyContent="center">
            <LazyLoadImage width={"50px"} src={IMAGES.locationFooter} alt="" />
            <Text
              m={0}
              style={{width: isMobile ? "335px" : "100%"}}
              sx={{
                textAlign: "center",
                fontFamily: "K2D",
                fontSize: isMobile
                  ? "15px"
                  : isTabletOrLaptop
                  ? "22px"
                  : "32px",
                fontWeight: 600,
                lineHeight: isMobileOrTablet ? "20px" : "42px",
                color: COLOR.white,
              }}
            >
              Xem hệ thống chi nhánh Phong Thủy Đại Nam trên toàn quốc
            </Text>
          </Flex>
          <a href={APP_ROUTES.BRANCHLIST} style={{textDecoration: "none"}}>

          <Stack
            bg={COLOR.white}
            p={isMobileOrTablet ? "12px 30px" : "12px 18px"}
            borderRadius="7px"
          >
            <Text
              m={0}
              sx={{
                fontFamily: "K2D",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "24px",
                color: COLOR.input,
              }}
            >
              Xem chi nhánh
            </Text>
          </Stack>
          </a>
        </ContainerAllProfiles>
      </Flex>
        }
      <Stack
        spacing={0}
        sx={{
          backgroundImage: `url(${IMAGES.bgFooter2})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        p="50px 0"
      >
        <ContainerAllProfiles
          direction="column"
          gap="30px"
          display={isMobileOrTablet ? "none" : "flex"}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <LazyLoadImage width="280px" src={IMAGES.logoFooter} alt="" />
            <Flex gap="20px">
              <Stack>
                <Text
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    color: COLOR.footerPrivate,
                  }}
                >
                  Chứng Nhận Bởi
                </Text>
                <LazyLoadImage width="126px" src={IMAGES.congthuong} alt="" />
              </Stack>
              <Stack>
                <Text
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    color: COLOR.footerPrivate,
                  }}
                >
                  Chứng Nhận Bởi
                </Text>
                <LazyLoadImage width="232px" src={IMAGES.dcma} alt="" />
              </Stack>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Stack
              spacing={0}
              gap="20px"
              display={isMobileOrTablet ? "none" : "flex"}
            >
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                Thông tin chung
              </Text>
              {info.map((item, index) => (
                <a href={item.link} key={index} target="_blank">
                  <Text
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                      color: COLOR.input,
                    }}
                    m={0}
                    sx={{
                      fontFamily: "K2D",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: COLOR.footerText,
                      transition: "all 235ms ease-in-out",
                    }}
                  >
                    {item.title}
                  </Text>
                </a>
              ))}
            </Stack>
            <Stack spacing={0} gap="20px">
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                Đào tạo
              </Text>
              {training.map((item, index) => (
                <a href={item.link} key={index} target="_blank">
                  <Text
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                      color: COLOR.input,
                    }}
                    m={0}
                    sx={{
                      fontFamily: "K2D",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: COLOR.footerText,
                      transition: "all 235ms ease-in-out",
                    }}
                  >
                    {item.title}
                  </Text>
                </a>
              ))}
            </Stack>{" "}
            <Stack
              spacing={0}
              gap="20px"
              display={isMobileOrTablet ? "none" : "flex"}
            >
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                dịch vụ
              </Text>
              {service.map((item, index) => (
                <a href={item.link} key={index} target="_blank">
                  <Text
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                      color: COLOR.input,
                    }}
                    m={0}
                    sx={{
                      fontFamily: "K2D",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: COLOR.footerText,
                      transition: "all 235ms ease-in-out",
                    }}
                  >
                    {item.title}
                  </Text>
                </a>
              ))}
            </Stack>{" "}
            <Stack
              spacing={0}
              gap="20px"
              display={isMobileOrTablet ? "none" : "flex"}
            >
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                quy trình
              </Text>
              {procedure.map((item, index) => (
                <a href={item.link} key={index} target="_blank">
                  <Text
                    _hover={{
                      transform: "translate3d(0, 2px, 0)",
                      color: COLOR.input,
                    }}
                    m={0}
                    sx={{
                      fontFamily: "K2D",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "24px",
                      color: COLOR.footerText,
                      transition: "all 235ms ease-in-out",
                    }}
                  >
                    {item.title}
                  </Text>
                </a>
              ))}
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                chăm sóc khách hàng
              </Text>
              <a href={`tel:0788 68 68 98`}>
                <Text
                  _hover={{
                    transform: "translate3d(0, 2px, 0)",
                    color: COLOR.input,
                  }}
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: COLOR.footerText,
                    transition: "all 235ms ease-in-out",
                  }}
                >
                  0788 68 68 98
                </Text>
              </a>
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                góp ý, khiếu nại
              </Text>
              <a href={`tel: 0812 83 83 38`}>
                <Text
                  _hover={{
                    transform: "translate3d(0, 2px, 0)",
                    color: COLOR.input,
                  }}
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: COLOR.footerText,
                    transition: "all 235ms ease-in-out",
                  }}
                >
                  0812 83 83 38
                </Text>
              </a>
            </Stack>
            <Stack gap="10px" alignItems="center">
              <Text
                m={0}
                sx={{
                  textAlign: "right",
                  fontFamily: "K2D",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                  textTransform: "uppercase",
                }}
              >
                kết nối với đại nam
              </Text>
              <Flex gap="15px">
                {social.map((item, index) => (
                  <a href={item.link} key={index}>
                    <Stack
                      sx={{
                        transition: "all 235ms ease-in-out",
                      }}
                      _hover={{
                        transform: "translate3d(0, 2px, 0)",
                      }}
                    >
                      <LazyLoadImage src={item.img} alt="" />
                    </Stack>
                  </a>
                ))}
              </Flex>
              <Stack spacing={0} gap="10px">
                <Text
                  m={0}
                  sx={{
                    textAlign: "right",
                    mt: "50px",
                    borderRadius: "16px",
                    fontFamily: "K2D",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    color: COLOR.black,
                    textTransform: "uppercase",
                  }}
                >
                  tải ứng dụng xhero
                </Text>

                <LazyLoadImage style={{borderRadius: "16px"}} width="150px" src={IMAGES.QRCodeApp} alt="" />
              </Stack>
            </Stack>
          </Flex>
        </ContainerAllProfiles>
        <ContainerAllProfiles
          direction="column"
          display={isMobileOrTablet ? "flex" : "none"}
        >
          <Text
            sx={{
              fontFamily: "K2D",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "20px",
              color: COLOR.black,
            }}
          >
            Tổng đài hỗ trợ
          </Text>
          <a href="tel: 0788 68 68 98">

          <Text
            sx={{
              fontFamily: "K2D",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: COLOR.footerPrivate,
            }}
          >
            Chăm sóc khách hàng :{" "}
            <span style={{ fontWeight: 700, color: COLOR.black }}>
              0788 68 68 98
            </span>
          </Text>
          </a>
          <a href="tel: 0812 83 83 38">

          <Text
            sx={{
              fontFamily: "K2D",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: COLOR.footerPrivate,
            }}
          >
            Góp ý, khiếu nại:{" "}
            <span style={{ fontWeight: 700, color: COLOR.black }}>
              0812 83 83 38
            </span>
          </Text>
          </a>
          <Stack gap="15px" spacing={0}>
            {data.map((i, r) => (
              <Stack gap="15px" key={r} spacing={0}>
                <Flex
                  gap="15px"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => setTab(tab === i.id ? null : i.id)}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    _hover: {
                      color: COLOR.input,
                    },
                  }}
                >
                  <Text
                    m={0}
                    fontSize="14px"
                    fontWeight={700}
                    lineHeight="21px"
                    fontFamily="K2D"
                  >
                    {i.title}
                  </Text>
                  <LazyLoadImage
                    src={IMAGES.expandIcon}
                    alt=""
                    style={{
                      transform:
                        tab === i.id ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Flex>
                <Stack
                  spacing={0}
                  gap="10px"
                  sx={{
                    maxHeight: tab === i.id ? "500px" : "0px",
                    overflow: "hidden",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  {i.desc.map((descItem, index) => (
                    <a
                      href={descItem.link}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      key={index}
                    >
                      <Text
                        m={0}
                        fontSize="14px"
                        fontWeight={400}
                        lineHeight="21px"
                        fontFamily="K2D"
                        key={index}
                        color={COLOR.footerPrivate}
                      >
                        {descItem.title}
                      </Text>
                    </a>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Flex justifyContent="space-between" alignItems="start" mt="20px">
            <Stack gap="10px" spacing={0}>
              <Text
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                }}
              >
                Kết nối với Đại Nam
              </Text>
              <Flex gap="15px">
                {social.map((item, index) => (
                  <a href={item.link} key={index}>
                    <LazyLoadImage src={item.img} alt="" />
                  </a>
                ))}
              </Flex>
              <Stack>
                <Text
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    color: COLOR.footerPrivate,
                    textTransform: "uppercase",
                  }}
                >
                  Chứng Nhận Bởi
                </Text>
                <LazyLoadImage width="137px" src={IMAGES.congthuong} alt="" />
                <LazyLoadImage width="152px" src={IMAGES.dcma} alt="" />
              </Stack>
            </Stack>
            <Stack spacing={0} gap="10px">
              <Text
                m={0}
                sx={{
                  textAlign: "center",
                  fontFamily: "K2D",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  color: COLOR.black,
                }}
              >
                Tải ứng dụng Xhero
              </Text>

              <LazyLoadImage style={{borderRadius: "16px"}} width="150px" src={IMAGES.QRCodeApp} alt="" />
            </Stack>
          </Flex>
        </ContainerAllProfiles>
      </Stack>
      <Flex
        sx={{
          backgroundImage: `url(${IMAGES.bgFooter3})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        p="70px 0"
      >
        <ContainerAllProfiles justifyContent="center">
          <Stack gap="10px">
            {title.map((item, index) => (
              <a href={item.link} key={index}>
                <Text
                  m={0}
                  sx={{
                    textAlign: "center",
                    fontFamily: "K2D",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: COLOR.white,
                    textTransform: "uppercase",
                  }}
                >
                  {item.title}
                </Text>
              </a>
            ))}
          </Stack>
        </ContainerAllProfiles>
      </Flex>
    </Stack>
  );
};
export default FooterAllProfiles;
