import React from "react";
import { Text } from "@chakra-ui/react";
interface Props {
  text: string;
  maxLength: number;
}

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};

const TruncatedText: React.FC<Props> = ({ text, maxLength }) => {
  const truncated = truncateText(text, maxLength);

  return <Text fontFamily="K2D" m={0}>{truncated}</Text>;
};

export default TruncatedText;
