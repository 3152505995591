import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { useRef, useState } from "react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import ContainerAllProfiles from "../ContainerAllProfiles";
import BackgroundLoader from "../LoadBgImage";
import BreadcrumbsPage from "../Breadcrumbs";

const Navbar: React.FC = () => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop } = useResponsive();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLDivElement>(null);

  const data = [
    {
      title: "Về chúng tôi",
      link: "https://phongthuydainam.vn/ve-chung-toi/",
    },
    {
      title: "Dịch vụ",
      link: "https://phongthuydainam.vn/dich-vu-phong-thuy/",
    },
    {
      title: "Đào tạo",
      link: "https://phongthuydainam.vn/danh-muc/dao-tao-phong-thuy/",
    },
    {
      title: "Vật phẩm",
      link: "https://phongthuydainam.vn/danh-muc/vat-pham-phong-thuy/",
    },
    {
      title: "Thạch thủy bình",
      link: "https://phongthuydainam.vn/danh-muc/thach-thuy-binh/",
    },
    {
      title: "Tin tức",
      link: "https://phongthuydainam.vn/chuyen-muc/tin-tuc/",
    },
  ];

  const animationStyle = `
    @keyframes scroll {
      0% { transform: translateX(100%); }
      100% { transform: translateX(-100%); }
    }
  `;

  const textStyle = {
    m: 0,
    textAlign: "center",
    fontFamily: "K2D",
    fontSize: isTabletOrLaptop ? "13px" : "14px",
    fontWeight: 700,
    lineHeight: "23px",
    color: COLOR.white,
    whiteSpace: "nowrap",
    // overflow: "hidden",
    width: "100%",
    animation: isMobileOrTablet ? `scroll 10s linear infinite` : "none",
  };
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      <style>{animationStyle}</style>
      <BackgroundLoader
        imageUrl={isTabletOrLaptop ? IMAGES.bgNavMb : IMAGES.bgNav}
        setIsLoaded={setIsLoaded}
        isLoaded={isLoaded}
      >
        <Flex background={COLOR.bgNav}>
          <ContainerAllProfiles>
            <Flex justifyContent="space-between" w="100%" p="5px 0">
              <Flex
                gap="20px"
                alignItems={isMobileOrTablet ? "start" : "center"}
                sx={{
                  width: isMobileOrTablet ? "100%" : "700px",
                  position: "relative",
                  overflow: isMobileOrTablet ? "hidden" : "block",
                }}
              >
                <img
                  style={{ display: isMobileOrTablet ? "none" : "flex" }}
                  width="30px"
                  src={IMAGES.announcement}
                  alt=""
                />
                <Text sx={textStyle}>
                  Khóa học Đại Đạo Chí Giản - Phong Thủy Cổ Học mang đến cho bạn
                  một cơ hội hiếm có để tìm hiểu và thực hành nghệ thuật phong
                  thủy
                </Text>
              </Flex>
              {/* <Flex gap="10px" display={isMobileOrTablet ? "none" : "flex"}>
                <img width="100px" src={IMAGES.ggP} alt="" />
                <img width="100px" src={IMAGES.AS} alt="" />
              </Flex> */}
            </Flex>
          </ContainerAllProfiles>
        </Flex>
        <ContainerAllProfiles direction="column" gap="20px" h="75px">
          <Flex justifyContent="space-between" alignItems="center" mt="8px">
            <Stack display={isMobile ? "flex" : "none"}>
              <div ref={btnRef} onClick={onOpen}>
                <img src={IMAGES.menu} alt="" />
              </div>
              <Drawer
                size="full"
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton
                    mt="55px"
                    size="100px"
                    color={COLOR.white}
                  />
                  <DrawerHeader
                    sx={{
                      backgroundImage: `url(${IMAGES.bgNavMb})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      p: "50px 20px",
                    }}
                  >
                    <img width="150px" src={IMAGES.logoDaiNam} alt="" />
                  </DrawerHeader>
                  <DrawerBody>
                    <Stack
                      gap="50px"
                      justifyContent="space-between"
                      w="100%"
                      p="20px"
                    >
                      <Stack gap="10px" w="100%" borderRadius="10px">
                        {data.map((item, index) => (
                          <a href={item.link} key={index}>
                            <Text
                              _hover={{
                                transform: "translate3d(0, 2px, 0)",
                                color: COLOR.input,
                                borderBottom: `2px solid ${COLOR.input}`,
                              }}
                              m={0}
                              sx={{
                                fontFamily: "K2D",
                                fontSize: "16px",
                                fontWeight: 800,
                                lineHeight: "24px",
                                color: COLOR.black,
                                transition: "all 235ms ease-in-out",
                                textTransform: "uppercase",
                              }}
                            >
                              {item.title}
                            </Text>
                          </a>
                        ))}
                      </Stack>
                    </Stack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Stack>
            <img
              width={isMobile ? "150px" : "180px"}
              src={IMAGES.logoDaiNam}
              alt=""
            />
            <a href="tel:1900 989 919">
              <Flex alignItems="center" gap="10px">
                <img src={IMAGES.headphone} alt="" />
                <Text
                  display={isMobile ? "none" : "flex"}
                  m={0}
                  sx={{
                    fontFamily: "K2D",
                    fontSize: "32px",
                    fontWeight: 800,
                    lineHeight: "39px",
                    color: COLOR.white,
                  }}
                >
                  1900 989 919
                </Text>
              </Flex>
            </a>
          </Flex>
        </ContainerAllProfiles>
      </BackgroundLoader>
      <Flex
        background={COLOR.white}
        p="18px 0 0 0"
        display={isMobile ? "none" : "flex"}
        h="60px"
      >
        <ContainerAllProfiles justifyContent="space-between">
          {data.map((item, index) => (
            <a href={item.link} key={index}>
              <Text
                _hover={{
                  transform: "translate3d(0, 2px, 0)",
                  color: COLOR.input,
                  borderBottom: `2px solid ${COLOR.input}`,
                }}
                m={0}
                sx={{
                  fontFamily: "K2D",
                  fontSize: "16px",
                  fontWeight: 800,
                  lineHeight: "24px",
                  color: COLOR.black,
                  transition: "all 235ms ease-in-out",
                  textTransform: "uppercase",
                }}
              >
                {item.title}
              </Text>
            </a>
          ))}
        </ContainerAllProfiles>
      </Flex>
      <Flex
        background={COLOR.bgBreadcrumb}
        p="18px 0 0 0"
        display={isMobile ? "none" : "flex"}
        h="60px"
      >
        <BreadcrumbsPage />
      </Flex>
    </>
  );
};

export default Navbar;
