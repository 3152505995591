import { Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import IMAGES from "../../constant/CONFIG_ROUTES/images";

const LoadingPage = () => {
  const overlayStyle = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const imageStyle = {
    width: "100px",
    height: "100px",
    animation: "spin 2s linear infinite",
  };

  const keyframesStyle = `
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `;
  return (
    <div>
      <div style={{...overlayStyle, position: "fixed"}}>
        <style>{keyframesStyle}</style>
        <img src={IMAGES.logoLoading} alt="Loading" style={imageStyle} />
      </div>
    </div>
  );
};
export default LoadingPage;
