import { Image, keyframes, Stack, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SlideCertificate from "../../component/SlideCertificate";
import SwiperReview from "../../component/Swiper";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
// import { DefaultProfile } from "../../constant/DataProfiles";
import { ProfileType } from "../../constant/DataProfiles/DataTypes";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Footer from "../../component/Footer";
import APP_ROUTES from "../../constant/CONFIG_ROUTES/app_routes";

import axios, { Axios } from "axios";
import BackgroundLoader from "../../component/LoadBgImage";
import LoadingPage from "../../component/LoadingPage";
import Aos from "aos";
const Home: React.FC = () => {
  type Card = { id?: string };
  const ProfileItem = useParams();
  const Item: Card = ProfileItem;
  const ProductItem = Item.id;
  const [profile, setProfile] = useState<ProfileType>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/experts/${ProfileItem.id}`
      );
      // console.log(data.data.data);
      setProfile(data.data.data);
      document.title = `Thầy ${data.data.data.fullName} `;
      Promise.all(
        Array.from(document.images)
          .filter((img) => !img.complete)
          .map(
            (img) =>
              new Promise((resolve) => {
                img.onload = img.onerror = resolve;
              })
          )
      ).then(() => {
        console.log("images finished loading");
        setIsLoading(false);
      });
    } catch (error) {
      navigate({
        pathname: APP_ROUTES.ALL,
      });
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;
  const div1Ref = useRef<HTMLDivElement>(null); // Chỉ định rõ kiểu là HTMLDivElement
  const div2Ref = useRef<HTMLDivElement>(null); // Chỉ định rõ kiểu là HTMLDivElement
  const [div1Visible, setDiv1Visible] = useState(true);
  const handleScroll = () => {
    if (div2Ref.current) {
      const div2Position = div2Ref.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      console.log(div2Position, windowHeight)
      if (div2Position < windowHeight && div2Position > 0) {
        setDiv1Visible(false);
      } else if (div2Position <= 0) {
        setDiv1Visible(true);
      } else {
        setDiv1Visible(true);
      }
    }
  };
  const [isLoaded, setIsLoaded] = useState(false);

  const isBacground = localStorage.getItem("background");
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   Aos.init();
  // });
  return (
    <>
      <BackgroundLoader
        imageUrl={IMAGES.bgContain}
        setIsLoaded={setIsLoaded}
        isLoaded={isLoaded}
      >
        {isLoading ? (
          <LoadingPage />
        ) : (
          <>
            <Stack spacing={5} fontFamily="K2D" className="profile">
              {div1Visible && (
                <Stack
                  ref={div1Ref}
                  style={{
                    position: "fixed",
                    zIndex: "99",
                    bottom: 30,
                    right: 35,
                    transition: "opacity 0.5s",
                  }}
                  gap={2}
                >
                  <a href="tel:1900989919">
                    <Tooltip
                      label="1900 989 919"
                      fontSize="md"
                      placement="left"
                    >
                      <Image
                        width="56px"
                        height="56px"
                        src={IMAGES.iconPhone}
                        animation={`${zoomInOut} 3s ease-in-out infinite`}
                      />
                    </Tooltip>
                  </a>
                  <a href="https://zalo.me/0788686898" target="_blank">
                    <Tooltip
                      label="Phong Thủy Đại Nam"
                      fontSize="md"
                      placement="left"
                    >
                      <Image
                        width="56px"
                        height="56px"
                        src={IMAGES.iconZalo}
                        animation={`${zoomInOut} 3s ease-in-out infinite`}
                      />
                    </Tooltip>
                  </a>
                </Stack>
              )}
              {isLoaded && <Section1 itemCard={profile} />}

              <div ref={div2Ref}>
              <Footer />
            </div>
            </Stack>
          </>
        )}
      </BackgroundLoader>
    </>
  );
};
export default Home;
