import styled from "styled-components";
import IMAGES from "../../../constant/CONFIG_ROUTES/images";
import { Text } from "@chakra-ui/react";
import COLOR from "../../../constant/CONFIG_ROUTES/color";

export const Wrapper = styled.div`
  background-image: url(${IMAGES.contain2});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 900px) {
    background-image: url(${IMAGES.s2Mb});
  }
`;
export const TextTitle = styled.span`
  font-family: K2D;
  font-size: 32px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: ${COLOR.textDeco};
  @media screen and (max-width: 900px) {
    font-size: 28px;
  }
  @media screen and (max-width: 575px) {
    font-size: 22px;
  }
`;
export const TextDecs = styled(Text)`
  font-family: K2D;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: justified;
  color: ${COLOR.textDeco};
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
export const TextInfoThay = styled.span`
  font-family: K2D;
  font-size: 24px;
  font-weight: 400;
  line-height: 42px;
  text-align: justified;
  color: ${COLOR.textDeco};
  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
