import styled from "styled-components";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";

export const Wrapper = styled.div`
  width: 100%;
  background: ${COLOR.bgBranch};
  padding: 32px 20px;
  .activeTab {
    background: ${COLOR.activeTabBranch};
    color: ${COLOR.whiteBold};
  }
  .slideDiv {
    width: 32%;
    @media screen and (max-width: 900px) {
      position: absolute;
      zindex: 10;
      transform: translateX(100%);
      transition: transform 0.5s ease-in-out;
      opacity: 0;
    }
  }

  .slideDiv.active {
    transform: translateX(0);
    width: 100%;
    height: 100%;
      opacity: 1;
  }
  @media screen and (max-width: 1200px) {
    padding: 32px 20px;
  }
  @media screen and (max-width: 575px) {
    padding: 32px 16px;
  }
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  .activetab: {
    background: ${COLOR.activeTabBranch};
    color: ${COLOR.whiteBold};
  }
`;
export const TabBranch = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  padding: 12px;
  box-shadow: 0px 1.02px 2.03px 0px #1018280d;
  background: ${COLOR.whiteBold};
  border-radius: 10px;
  border: 1.02px solid ${COLOR.borderTab};
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    width: max-content;
  }
  @media screen and (max-width: 575px) {
    width: max-content;
  }
`;
export const ButtonContact = styled.div`
background: linear-gradient(35.48deg, #A70707 28.31%, #F47575 86.97%);
border-radius; 6px;
padding: 5px 20px;
border-radius: 10px;
@media screen and (max-width: 1200px) {
padding: 10px 18px;

}
@media screen and (max-width: 900px) {
width: 100%;
}
@media screen and (max-width: 575px) {
width: 100%;
}
`;
export const TextContact = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 17.08px;
  text-align: left;
  color: ${COLOR.whiteBold};
`;
export const BranchCard = styled.div`
  background-image: url(${IMAGES.bgBranchItem});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 35px;
  border-radius: 8px;
  height: 100%;
  &:hover {
    background-image: url(${IMAGES.bgBranchHover});
    .hoverText {
      background: ${COLOR.textLinear};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .hoverLabel {
      color: #d89a4d;
    }
    .hoverTextButton {
      background: ${COLOR.textLinear};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .branchPhone {
      background-image: url(${IMAGES.iconPhoneHover});
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .branchPlace {
      background-image: url(${IMAGES.iconPlaceHover});
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .branchPhone {
    background-image: url(${IMAGES.branchPhone});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .branchPlace {
    background-image: url(${IMAGES.branchPlace});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media screen and (max-width: 575px) {
    padding: 20px 25px;
  }
`;
export const TextCard = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  background: linear-gradient(35.48deg, #a70707 28.31%, #f47575 86.97%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
export const BranchListBox = styled.div`
  background-image: url(${IMAGES.bgBranchRight});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 65%;
  padding: 32px;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    padding: 0;
    background: none;
  }
`;
export const ContentBranch = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: ${COLOR.textContent};
  @media screen and (max-width: 575px) {
    font-size: 16px;
  }
`;
export const TextButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${COLOR.input};

  @media screen and (max-width: 1200px) {
    font-size: 13px;
  }
  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
`;
export const TextSlide = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  background: ${COLOR.textLinear};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const LabelSlide = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${COLOR.whiteBold};
`;
export const WrapperModal = styled.div`
  background-image: url(${IMAGES.bgPopup});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
export const ModalDeriction = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #515151;
`;
export const PopupContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
  width: 200px;
`;

export const PopupTitle = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: ${COLOR.textBranch};
`;

export const PopupContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #1c233e;
`;
export const TextSearch = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #1c233e;
  width: 100%;
  margin-top: 10px;
`
