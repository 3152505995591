import {
  Button,
  Collapse,
  Divider,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  keyframes,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContainerAllProfiles from "../../component/ContainerAllProfiles";
import ProfilesCard from "../../component/ProfilesCard";
import APP_ROUTES from "../../constant/CONFIG_ROUTES/app_routes";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
// import { DefaultProfile } from "../../constant/DataProfiles";
import {
  AllPosition,
  ProfileType,
  provinceType,
} from "../../constant/DataProfiles/DataTypes";
import useResponsive from "../../hooks/useResponsive";
import InfoSwiper from "../../component/InfoSwiper";
import axios from "axios";
import LoadingPage from "../../component/LoadingPage";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Footer from "../../component/Footer";
import FooterAllProfiles from "../../component/FooterAllProfiles";
import Navbar from "../../component/Navbar";
import {
  TextFilter,
  TextInfo,
  TextSelectedPosition,
  TextSpecialize,
  TextTitle,
  TextWellcome,
} from "./style";

type AllTypePositionOrAllPosition = AllPosition | "";

const specializations = [
  {
    title: "Phong Thủy Dương Trạch",
    key: "nhà ở",
  },
  {
    title: "Phong Thủy Âm Trạch",
    key: "âm trạch",
  },
  {
    title: "Phong Thủy Doanh Nghiệp",
    key: "doanh nghiệp",
  },
  {
    title: "Xem Ngày Vượng Cát",
    key: "xem ngày",
  },
  {
    title: "Kỳ Môn Độn Giáp",
    key: "kỳ môn",
  },
];

const OFFSET = 0;
const LIMIT = 3;

const AllProfile = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const { isSamsungZFold5, isMobile, isMobileOrTablet, isTabletOrLaptop } =
    useResponsive();
  const [profiles, setProfiles] = useState<ProfileType[]>([]);
  const [provinceFilter, setProvinceFilter] = useState("");
  const [specializeFilter, setSpecializeFilter] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [listProvince, setListProvince] = useState<provinceType[]>([]);
  const [expertFilter, setExpertFilter] =
    useState<AllTypePositionOrAllPosition>("");
  const [selectedPosition, setSelectedPosition] = useState<string | null>(null);
  const [totalPages, setTotalPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const offset = useMemo(
    () => Number(searchParams.get("offset")) || OFFSET,
    [searchParams]
  );

  const limit = useMemo(
    () => Number(searchParams.get("limit")) || LIMIT,
    [searchParams]
  );

  // let name = useMemo(() => searchParams.get("name"), [searchParams]);
  const getListProvince = async () => {
    try {
      const rs = await axios.get(`${process.env.REACT_APP_API_URL}/province`);
      // console.log(rs.data.data.data);
      const arr = rs.data.data.data.filter((i: any) => i.name)
      console.log(arr)
      setListProvince(rs.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getData = async (type: any) => {
    try {
      if (type === "all") {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}/experts?skip=${offset}&limit=${limit}`
        );
        setProfiles(data.data.data.data);
        setTotalPage(Math.ceil(Number(data.data.data.total) / limit));
        setIsLoading(false);
        return data.data.data.data;
      } else {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}/experts?skip=${offset}&limit=${limit}&fullName=${searchValue}&province=${provinceFilter}&majors=${specializeFilter}&position=${expertFilter}`
        );
        setProfiles(data.data.data.data);
        setTotalPage(Math.ceil(Number(data.data.data.total) / limit));
        return data.data.data.data;
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const filterProfiles = useCallback(async () => {
    if (
      provinceFilter === "" &&
      expertFilter === "" &&
      specializeFilter === "" &&
      searchValue === ""
    ) {
      await getData("all");
      return;
    } else {

      await getData("keyWord");
    }
  }, [
    expertFilter,
    limit,
    offset,
    specializeFilter,
    provinceFilter,
    searchValue,
  ]);
  // const sortedBranches = Object.values();
  const sortedPosition = Object.values(AllPosition);
  useEffect(() => {
    filterProfiles();
  }, [filterProfiles]);
  useEffect(() => {
    getListProvince();
  }, []);
  const name = useMemo(() => searchParams.get("name"), [searchParams]);
  const filterName = useMemo(
    () =>
      debounce((name: string) => {
        if (name.trim()) {
          setSearchValue(name.trim());
          filterProfiles();
          navigate({
            pathname: APP_ROUTES.ALL,
            search: `?offset=${OFFSET}&limit=${LIMIT}&name=${name}`,
          });
        } else {
          setSearchValue("");
          navigate({
            pathname: APP_ROUTES.ALL,
            search: `?offset=${OFFSET}&limit=${LIMIT}&name=${name}`,
          });
        }
      }, 1000),
    [navigate,name]
  );

  const getPaginationGroup = () => {
    const currentPage = offset;
    let start = Math.max(1, currentPage);
    let end = Math.min(totalPages, currentPage + 2);
    let prev = currentPage - 1;
    const paginationGroup = [];
    for (let i = prev; i <= end; i++) {
      paginationGroup.push(i);
    }
    return paginationGroup;
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleList = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNextPage = () => {
    if (offset < totalPages) {
      handleScroll();
      navigate({
        pathname: APP_ROUTES.ALL,
        search: `?offset=${offset + 1}&limit=${limit}}`,
      });
    }
  };

  const handlePrevPage = () => {
    if (offset > 0) {
      handleScroll();
      navigate({
        pathname: APP_ROUTES.ALL,
        search: `?offset=${offset - 1}&limit=${limit}}`,
      });
    }
  };
  const handleScroll = () => {
    const event = new CustomEvent("scrollToTarget");
    window.dispatchEvent(event);
  };
  useEffect(() => {
    const handleScrollToTarget = () => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    window.addEventListener("scrollToTarget", handleScrollToTarget);

    return () => {
      window.removeEventListener("scrollToTarget", handleScrollToTarget);
    };
  }, []);
  const handleLoadImg = () => {
    setIsLoadingImage(true);
  };
  const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [loadTime, setLoadTime] = useState(0);

  useEffect(() => {
    // Bắt đầu đo thời gian
    const startTime = performance.now();

    const fetchData = async () => {
      // Giả sử đây là hoạt động tải dữ liệu thực sự hoặc nội dung khác
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Giả lập thời gian tải dữ liệu

      // Kết thúc đo thời gian
      const endTime = performance.now();

      // Tính toán thời gian tải
      setLoadTime(endTime - startTime);

      setIsLoadingPage(false); // Sau khi tất cả nội dung đã được tải xong
    };

    fetchData();
  }, []);
  return (
    <>
      <Stack spacing={0}>
        <Stack
          spacing={0}
          position="relative"

          // justifyContent="space-between"
        >
          <Stack
            style={{ position: "fixed", zIndex: "99", bottom: 20, right: 20 }}
            gap={2}
          >
            <a href="tel:1900989919">
              <Tooltip label="1900 989 919" fontSize="md" placement="left">
                <Image
                  width="56px"
                  height="56px"
                  src={IMAGES.iconPhone}
                  animation={`${zoomInOut} 3s ease-in-out infinite`}
                />
              </Tooltip>
            </a>
            <a href="https://zalo.me/0788686898" target="_blank">
              <Tooltip
                label="Phong Thủy Đại Nam"
                fontSize="md"
                placement="left"
              >
                <Image
                  width="56px"
                  height="56px"
                  src={IMAGES.iconZalo}
                  animation={`${zoomInOut} 3s ease-in-out infinite`}
                />
              </Tooltip>
            </a>
          </Stack>
          <LazyLoadImage
            src={IMAGES.bgProfiles}
            alt="Bird"
            onLoad={handleLoadImg}
          />
          {isLoadingImage && (
            <ContainerAllProfiles
              direction="column"
              justifyContent="space-between"
              position="absolute"
              bottom={isMobileOrTablet ? "-20px" : 0}
              left="50%"
              gap={isTabletOrLaptop ? "5px" : "50px"}
              transform="translate(-50%,0%)"
            >
              <TextWellcome
                style={{
                  display: isMobile || isMobileOrTablet ? "none" : "block",
                }}
              >
                Chào mừng đến với
                <TextWellcome style={{ margin: "0 10px", fontWeight: 600 }}>
                  Phong Thủy Đại Nam!
                </TextWellcome>
                Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam là những người đã
                được tuyển chọn kỹ lưỡng và đào tạo bài bản, mang trong mình sứ
                mệnh cải thiện không gian sống và làm việc của người Việt nhờ
                vận dụng đúng kiến thức phong thủy khoa học.
              </TextWellcome>
              {!isLoading && (
                <InputGroup w="full" alignItems="center">
                  <InputLeftElement
                    pointerEvents="none"
                    mt={isTabletOrLaptop ? 0 : "10px"}
                  >
                    <LazyLoadImage src={IMAGES.searchIcon} alt="" />
                  </InputLeftElement>

                  <Input
                    h={isTabletOrLaptop ? "40px" : "60px"}
                    border={`1px solid ${COLOR.inputBorder}`}
                    bg={COLOR.white}
                    fontFamily="K2D"
                    _placeholder={{ color: COLOR.placeholder }}
                    color={COLOR.black}
                    placeholder="Tìm kiếm theo tên Thầy, Chuyên gia"
                    onChange={(event) => filterName(event.target.value)}
                  />
                </InputGroup>
              )}
            </ContainerAllProfiles>
          )}
        </Stack>
        {isLoading && isLoadingImage === false ? (
          <LoadingPage />
        ) : (
          <>
            <Stack
              ref={targetRef}
              spacing={0}
              mt="50px"
              sx={{
                // bg: COLOR.white,
                backgroundImage: `url(${IMAGES.bgAll})`,
                backgroundRepeat: "repeat",
                backgroundSize: "100% 100%",
              }}
            >
              <ContainerAllProfiles direction="column" mb="50px">
                <TextTitle>
                  Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam
                </TextTitle>
                <LazyLoadImage src={IMAGES.lineAll} alt="" />
                {!isLoadingPage && (
                  <>
                    <Flex
                      mt="20px"
                      display={isTabletOrLaptop ? "flex" : "none"}
                      justifyContent="space-between"
                    >
                      <Menu>
                        <MenuButton
                          sx={{
                            border: "none",
                          }}
                          as={IconButton}
                          aria-label="Options"
                          icon={<img src={IMAGES.hamburger} alt="" />}
                          variant="outline"
                        />
                        <MenuList p={0}>
                          <Stack
                            display={isTabletOrLaptop ? "flex" : "none"}
                            gap="10px"
                            w="100%"
                            borderRadius="10px"
                            bg={COLOR.white}
                          >
                            <Flex
                              bg={COLOR.activeTab}
                              borderRadius="5px"
                              p="10px"
                            >
                              <TextFilter
                                style={{
                                  color:
                                    provinceFilter === ""
                                      ? COLOR.whiteBold
                                      : COLOR.whiteBold,
                                }}
                              >
                                Lọc theo
                              </TextFilter>
                            </Flex>

                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              p="0 10px"
                            >
                              <TextFilter
                                onClick={() => {
                                  setProvinceFilter("");
                                  setExpertFilter(expertFilter);
                                  setSpecializeFilter("");

                                  navigate({
                                    pathname: APP_ROUTES.ALL,
                                    search: `?offset=${0}&limit=${3}`,
                                  });
                                }}
                                style={{ color: COLOR.black }}
                              >
                                Toàn quốc
                              </TextFilter>
                              <Button onClick={toggleList} mt={2} bg="none">
                                {isExpanded ? (
                                  <LazyLoadImage
                                    width={20}
                                    height={20}
                                    src={IMAGES.decreaseIcon}
                                    alt=""
                                  />
                                ) : (
                                  <LazyLoadImage
                                    width={20}
                                    height={20}
                                    src={IMAGES.expandIcon}
                                    alt=""
                                  />
                                )}
                              </Button>
                            </Flex>
                            <Collapse in={isExpanded} animateOpacity>
                              <List
                                spacing={3}
                                pl={0}
                                maxH="300px"
                                overflowY="scroll"
                                p="0 10px"
                              >
                                {listProvince.map((type) => (
                                  <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <ListItem
                                      _hover={{
                                        color:
                                          provinceFilter === type.name
                                            ? COLOR.bgContact
                                            : COLOR.black,
                                      }}
                                      fontWeight={
                                        provinceFilter === type.name ? 700 : 400
                                      }
                                      key={type.name}
                                      fontFamily="K2D"
                                      onClick={() => {
                                        setProvinceFilter(type.name);
                                        setSearchParams((prev) => ({
                                          ...prev,
                                          offset: "0",
                                        }));
                                      }}
                                      cursor="pointer"
                                      color={
                                        provinceFilter === type.name
                                          ? COLOR.bgContact
                                          : COLOR.black
                                      }
                                    >
                                      {type.name}
                                    </ListItem>
                                    <LazyLoadImage
                                      style={{
                                        display:
                                          provinceFilter === type.name
                                            ? "block"
                                            : "none",
                                      }}
                                      src={IMAGES.check}
                                      alt=""
                                    />
                                  </Flex>
                                ))}
                              </List>
                            </Collapse>
                            <Divider
                              w="100%"
                              m={0}
                              background={COLOR.divider}
                              height="1px"
                            />
                            <TextFilter style={{ color: COLOR.black, paddingRight: "10px" }}>
                              Chuyên môn
                            </TextFilter>
                            <List
                              spacing={3}
                              maxHeight="500px"
                              overflowY="auto"
                              pl={0}
                              p="0 10px"
                            >
                              {specializations.map((specialize, index) => (
                                <ListItem
                                  key={index}
                                  onClick={() =>
                                    setSpecializeFilter(specialize.key)
                                  }
                                  cursor="pointer"
                                >
                                  <TextSpecialize
                                    fontWeight={
                                      specializeFilter === specialize.key
                                        ? 700
                                        : 400
                                    }
                                    _hover={{
                                      color:
                                        specializeFilter === specialize.key
                                          ? COLOR.bgContact
                                          : COLOR.black,
                                    }}
                                    style={{
                                      color:
                                        specializeFilter === specialize.key
                                          ? COLOR.bgContact
                                          : COLOR.black,
                                    }}
                                  >
                                    {specialize.title}
                                  </TextSpecialize>
                                </ListItem>
                              ))}
                            </List>
                          </Stack>
                        </MenuList>
                      </Menu>
                      <Stack
                        display="flex"
                        border="2px solid #460D0B"
                        borderRadius="10px"
                      >
                        <Menu>
                          <MenuButton
                            px={2}
                            py={2}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="1px"
                            height="46px"
                            bg={COLOR.expert}
                          >
                            <Flex alignItems="center" gap="10px">
                              <TextSelectedPosition
                                m={0}
                                sx={{
                                  fontSize: isMobile ? "12px" : "20px",
                                  fontWeight: 600,
                                  fontFamily: "K2D",
                                  lineHeight: "20px",
                                  color: "#661800",
                                }}
                              >
                                {selectedPosition === null
                                  ? "Cấp bậc"
                                  : selectedPosition === ""
                                  ? "Tất cả"
                                  : `${selectedPosition} Phong Thủy`}
                              </TextSelectedPosition>
                              <LazyLoadImage
                                width={20}
                                height={20}
                                src={IMAGES.expandIcon}
                                alt=""
                              />
                            </Flex>
                          </MenuButton>
                          <MenuList>
                            <List spacing={0} pl={0}>
                              {sortedPosition.map((type) => (
                                <ListItem
                                  p="5px 10px"
                                  _hover={{
                                    color: COLOR.bgContact,
                                    fontWeight: 700,
                                  }}
                                  fontWeight={expertFilter === type ? 700 : 400}
                                  key={type}
                                  fontSize="22px"
                                  fontFamily="K2D"
                                  onClick={() => {
                                    setExpertFilter(type);
                                    setSelectedPosition(type);
                                    setSearchParams((prev) => ({
                                      ...prev,
                                      offset: "0",
                                    }));
                                  }}
                                  cursor="pointer"
                                  color={
                                    expertFilter === type
                                      ? COLOR.bgContact
                                      : COLOR.black
                                  }
                                >
                                  {type === ""
                                    ? `Tất cả`
                                    : `${type} Phong Thủy`}
                                </ListItem>
                              ))}
                            </List>
                          </MenuList>
                        </Menu>
                      </Stack>
                    </Flex>
                    <Flex w="100%" gap="20px" mt="40px">
                      <Stack
                        display={isTabletOrLaptop ? "none" : "flex"}
                        gap="10px"
                        w="20%"
                        // p="20px"
                        height="50%"
                        borderRadius="10px"
                        sx={{
                          backgroundImage: `url(${IMAGES.bgFilter})`,
                          backgroundRepeat: "repeat",
                          backgroundSize: "100% 100%",
                        }}
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <TextFilter
                            onClick={() => {
                              setProvinceFilter("");
                              setExpertFilter(expertFilter);
                              setSpecializeFilter("");
                              navigate({
                                pathname: APP_ROUTES.ALL,
                                search: `?offset=${0}&limit=${3}`,
                              });
                            }}
                            style={{ color: COLOR.black }}
                          >
                            Toàn quốc
                          </TextFilter>
                          <Button onClick={toggleList} mt={2}>
                            {isExpanded ? (
                              <img
                                width={20}
                                height={20}
                                src={IMAGES.decreaseIcon}
                                alt=""
                              />
                            ) : (
                              <img
                                width={20}
                                height={20}
                                src={IMAGES.expandIcon}
                                alt=""
                              />
                            )}
                          </Button>
                        </Flex>
                        <List
                          spacing={3}
                          pl={0}
                          height={isExpanded ? "1000px" : "500px"}
                          transition="height 300ms ease-in-out"
                          overflowY="auto"
                        >
                          {listProvince.map((type) => (
                            <ListItem
                              _hover={{
                                color: COLOR.bgContact,
                                fontWeight: 700,
                              }}
                              fontWeight={
                                provinceFilter === type.name ? 700 : 400
                              }
                              key={type.name}
                              fontSize="22px"
                              fontFamily="K2D"
                              onClick={() => {
                                setProvinceFilter(type.name);
                                // searchParams.set("offset", "0");
                                setSearchParams((prev) => ({
                                  ...prev,
                                  offset: "0",
                                }));
                              }}
                              cursor="pointer"
                              color={
                                provinceFilter === type.name
                                  ? COLOR.bgContact
                                  : COLOR.black
                              }
                            >
                              {type.name}
                            </ListItem>
                          ))}
                        </List>
                      </Stack>

                      <Stack w={isTabletOrLaptop ? "100%" : "80%"}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <div
                            className="scrollWidth"
                            style={{ overflow: "auto" }}
                          >
                            <List
                              pl={0}
                              display={isTabletOrLaptop ? "none" : "flex"}
                              mb={0}
                              w={900}
                            >
                              <Flex gap="10px">
                                {specializations.map((specialize, index) => (
                                  <ListItem
                                    key={index}
                                    onClick={() => {
                                      setSpecializeFilter(specialize.key);
                                      setSearchParams((prev) => ({
                                        ...prev,
                                        offset: "0",
                                      }));
                                    }}
                                    cursor="pointer"
                                    border={`1px solid ${COLOR.tab}`}
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="wrap"
                                    justifyContent="center"
                                    p="10px 12px"
                                    _hover={{
                                      background: COLOR.activeTab,
                                    }}
                                    borderRadius="50px"
                                    bg={
                                      specializeFilter === specialize.key
                                        ? COLOR.activeTab
                                        : "none"
                                    }
                                    transition="background-color 0.3s, border-color 0.3s"
                                  >
                                    <TextSpecialize
                                      m={0}
                                      fontSize={14}
                                      fontFamily="K2D"
                                      fontWeight={
                                        specializeFilter === specialize.key
                                          ? 700
                                          : 500
                                      }
                                      _hover={{
                                        color:
                                          specializeFilter === specialize.key
                                            ? COLOR.white
                                            : COLOR.white,
                                      }}
                                    >
                                      {specialize.title}
                                    </TextSpecialize>
                                  </ListItem>
                                ))}
                              </Flex>
                            </List>
                          </div>
                          <Stack
                            display={isTabletOrLaptop ? "none" : "flex"}
                            border="2px solid #460D0B"
                            borderRadius="10px"
                          >
                            <Menu>
                              <MenuButton
                                px={2}
                                py={2}
                                transition="all 0.2s"
                                borderRadius="md"
                                borderWidth="1px"
                                height="46px"
                                bg={COLOR.expert}
                              >
                                <Flex
                                  alignItems="center"
                                  gap="10px"
                                  w="max-content"
                                >
                                  <TextSelectedPosition m={0}>
                                    {selectedPosition === null
                                      ? "Cấp bậc"
                                      : selectedPosition === ""
                                      ? "Tất cả"
                                      : `${selectedPosition} Phong Thủy`}
                                  </TextSelectedPosition>

                                  <img
                                    width={20}
                                    height={20}
                                    src={IMAGES.expandIcon}
                                    alt=""
                                  />
                                </Flex>
                              </MenuButton>
                              <MenuList>
                                <List spacing={0} pl={0}>
                                  {sortedPosition.map((type) => (
                                    <ListItem
                                      p="5px 10px"
                                      _hover={{
                                        color: COLOR.bgContact,
                                        fontWeight: 700,
                                      }}
                                      fontWeight={
                                        expertFilter === type ? 700 : 400
                                      }
                                      key={type}
                                      fontSize="22px"
                                      fontFamily="K2D"
                                      onClick={() => {
                                        setExpertFilter(type);
                                        setSelectedPosition(type);
                                        setSearchParams((prev) => ({
                                          ...prev,
                                          offset: "0",
                                        }));
                                      }}
                                      cursor="pointer"
                                      color={
                                        expertFilter === type
                                          ? COLOR.bgContact
                                          : COLOR.black
                                      }
                                    >
                                      {type === ""
                                        ? "Tất cả"
                                        : `${type} Phong Thủy`}
                                    </ListItem>
                                  ))}
                                </List>
                              </MenuList>
                            </Menu>
                          </Stack>
                        </Flex>
                        <Stack>
                          <Stack
                            sx={{
                              gap: "20px",
                              mt: isMobile ? 0 : "15px",
                            }}
                            spacing={0}
                          >
                            {profiles.length > 0 ? (
                              profiles.map((item) => (
                                <Stack spacing={0} key={item.id}>
                                  <ProfilesCard profile={item} />
                                </Stack>
                              ))
                            ) : (
                              <Text fontFamily="K2D" fontSize="20px">
                                Không tìm thấy thông tin
                              </Text>
                            )}
                          </Stack>
                          {profiles.length > 0 && (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              mt={4}
                            >
                              <Button
                                sx={{
                                  bg: "none",
                                }}
                                _hover={{ bg: "none" }}
                                isDisabled={offset === 0}
                                onClick={handlePrevPage}
                              >
                                <img
                                  style={{ width: "40px" }}
                                  src={IMAGES.prevPagination}
                                  alt=""
                                />
                              </Button>
                              {getPaginationGroup().map((item, index) => (
                                <>
                                  {item > 0 && (
                                    <Button
                                      key={index}
                                      _hover={{
                                        bg: COLOR.input,
                                        color: COLOR.white,
                                      }}
                                      onClick={() => {
                                        navigate({
                                          pathname: APP_ROUTES.ALL,
                                          search: `?offset=${
                                            item - 1
                                          }&limit=${limit})}`,
                                        });
                                        handleScroll();
                                      }}
                                      sx={{
                                        borderRadius: "50%",
                                        background:
                                          offset === item - 1
                                            ? COLOR.input
                                            : "transparent",
                                        color:
                                          offset === item - 1
                                            ? COLOR.white
                                            : COLOR.paginationText,
                                      }}
                                    >
                                      {item}
                                    </Button>
                                  )}
                                </>
                              ))}
                              {offset < totalPages - 2 && (
                                <>
                                  {offset < totalPages - 3 && (
                                    <Button
                                      _hover={{
                                        bg: "none",
                                      }}
                                    >
                                      ...
                                    </Button>
                                  )}
                                  <Button
                                    _hover={{
                                      bg: "none",
                                    }}
                                    onClick={() => {
                                      handleScroll();

                                      navigate({
                                        pathname: APP_ROUTES.ALL,
                                        search: `?offset=${
                                          totalPages - 1
                                        }&limit=${limit}}`,
                                      });
                                    }}
                                    sx={{
                                      borderRadius: "50%",
                                    }}
                                  >
                                    {totalPages}
                                  </Button>
                                </>
                              )}
                              <Button
                                sx={{
                                  bg: "none",
                                }}
                                _hover={{ bg: "none" }}
                                isDisabled={offset >= totalPages - 1}
                                onClick={handleNextPage}
                              >
                                <img
                                  style={{ width: "40px" }}
                                  src={IMAGES.nextPagination}
                                  alt=""
                                />
                              </Button>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Flex>
                    <img
                      src={IMAGES.lineAll}
                      alt=""
                      style={{ marginTop: "30px" }}
                    />

                    <Stack mt="30px">
                      <Stack
                        spacing={0}
                        sx={{
                          backgroundImage: `url(${IMAGES.reasonBig})`,
                          backgroundRepeat: "repeat",
                          backgroundSize: "100% 100%",
                        }}
                        p="20px"
                      >
                        <TextTitle
                          style={{
                            textAlign: "center",
                            padding: "20px 0",
                            margin: "0 auto",
                          }}
                        >
                          LÝ DO NÊN CHỌN THẦY/CHUYÊN GIA PHONG THỦY ĐẠI NAM
                        </TextTitle>
                      </Stack>
                      <TextWellcome style={{ fontWeight: 600 }}>
                        Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam
                        <TextWellcome style={{ marginLeft: "10px" }}>
                          là tập hợp những cá nhân uy tín, dày dặn kinh nghiệm
                          và tâm huyết trong lĩnh vực phong thủy, với kĩ năng
                          chuyên môn cao về kiến thức phong thủy cổ học và khoa
                          học hiện đại, đồng thời có khả năng ứng dụng linh hoạt
                          vào thực tế để mang lại những giải pháp tối ưu đưa tới
                          khách hàng.
                        </TextWellcome>
                      </TextWellcome>
                      <TextInfo>
                        Điểm nổi bật của đội ngũ Thầy/Chuyên gia Phong Thủy Đại
                        Nam:
                      </TextInfo>
                      <InfoSwiper />
                    </Stack>
                  </>
                )}
              </ContainerAllProfiles>
            </Stack>
          </>
        )}
      </Stack>
      {!isLoading && <FooterAllProfiles />}
    </>
  );
};

export default AllProfile;
