/* eslint-disable no-return-assign */
import {
  Divider,
  Flex,
  keyframes,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import {
  AllPosition,
  ProfileType,
} from "../../constant/DataProfiles/DataTypes";
import useResponsive from "../../hooks/useResponsive";
import TruncatedText from "../TruncatedText";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ANIMATION_TIME = 500;

const ProfilesCard = ({ profile }: { profile: ProfileType }) => {
  const [dataFilter, setDataFilter] = useState<ProfileType>();
  const {
    isMobile,
    isDesktop,
    isTablet,
    isSamsungZFold5,
    isMobileOrTablet,
    isTabletOrLaptop,
  } = useResponsive();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [linkName, setLinkName] = useState("");
  const navigate = useNavigate();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  const vibrateAnimation = `
  @keyframes vibrate {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
  }
`;
  const colorChange = keyframes`
0% {
  color: #161616;
}
    25% {
  color: #72130FAB;
}
50% {
  color: #EE433C;
}
100% {
  color: #ED0E05;
}
`;

  useEffect(() => {
    setDataFilter(profile);
  }, []);
  let textColor = COLOR.black;
  let bgColor = COLOR.expert3;

  switch (profile.position) {
    case AllPosition.chuyengia:
      textColor = COLOR.expert1Text;
      bgColor = COLOR.expert1;
      break;
    case AllPosition.thay:
      textColor = COLOR.expert2Text;
      bgColor = COLOR.expert2;

      break;
    default:
      break;
  }
  const getLink = () => {
    const expert = profile.fullName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D")
      .replaceAll(" ", "-");
    setLinkName(expert);
  };
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getLink();
  }, []);
  useEffect(() => {
    const img = new Image();
    img.src = isMobile ? IMAGES.bgCardProfileMb : IMAGES.bgCardProfile;
    img.onload = () => setIsLoaded(true);
  }, [IMAGES.bgCardProfile]);
  return (
    <>
      <Stack
        className={`background-image-top ${isLoaded ? "loaded" : ""}`}
        sx={{
          backgroundImage: isMobile
            ? IMAGES.bgCardProfileMb
            : IMAGES.bgCardProfile,
          position: "relative",
          borderRadius: "10px",
          backgroundSize: "100% 100%",
          p: isMobile ? "20px 2px 0 20px" : "30px 20px 0 20px",
        }}
      >
        <>
          <a
            href={`/${profile?.key}`}
            style={{ width: "100%", height: "auto" }}
          >
            <Flex
              // direction={isMobile ? "column" : "row"}
              justifyContent={
                isMobile
                  ? "center"
                  : isSamsungZFold5
                  ? "center"
                  : isMobileOrTablet
                  ? "center"
                  : "space-between"
              }
              gap={isMobile ? 0 : "10px"}
              w="100%"
            >
              <img className="imgSmall" src={profile.avatar} alt="avatar" />
              <>
                <Stack
                  justifyContent="center"
                  direction={isMobile || isTablet ? "column" : "row"}
                >
                  <Stack
                    p="0"
                    spacing={0}
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                  >
                    <Stack
                      borderRadius="8px"
                      spacing={0}
                      gap="4px"
                      p={isMobile ? "0px 5px" : "5px 10px"}
                    >
                      <Text
                        m={0}
                        borderRadius="4px"
                        p="10px"
                        bg={bgColor}
                        fontFamily="K2D"
                        fontWeight={600}
                        fontSize={isMobile ? "14px" : "20px"}
                        lineHeight={isMobile ? "20px" : "39px"}
                        textAlign="left"
                        color={textColor}
                      >
                        {profile.title}
                      </Text>
                      <Stack gap="10px" direction="column">
                        <a href={`/${profile?.key}`}>
                          <Text
                            m={0}
                            fontFamily="K2D"
                            fontWeight={700}
                            fontSize={isMobile ? "16px" : "34px"}
                            lineHeight={isMobile ? "20px" : "51px"}
                            textAlign="left"
                            color={COLOR.input}
                          >
                            <span>{profile.fullName}</span>
                          </Text>
                        </a>
                        <Flex
                          alignItems="center"
                          gap="5px"
                          display={isMobileOrTablet ? "flex" : "none"}
                        >
                          <img src={IMAGES.PLocationIcon} alt="" />
                          <Text
                            m={0}
                            fontFamily="K2D"
                            fontWeight={500}
                            fontSize={isMobile ? "12px" : "20px"}
                            lineHeight={isMobile ? "20px" : "27px"}
                            textAlign="left"
                            color={COLOR.black}
                          >
                            CN {profile?.branchName}
                          </Text>
                        </Flex>
                      </Stack>

                      {/* <Divider w="50%" m={0} background={COLOR.divider} height="2px" /> */}
                      <Flex
                        alignItems="center"
                        gap="10px"
                        display={isMobileOrTablet ? "none" : "flex"}
                      >
                        <img src={IMAGES.PLocationIcon} alt="" />
                        <Text
                          m={0}
                          fontFamily="K2D"
                          fontWeight={500}
                          fontSize="15px"
                          lineHeight="27px"
                          textAlign="left"
                          color={COLOR.black}
                        >
                          Chi nhánh {profile?.branchName}
                        </Text>
                      </Flex>
                      <Flex
                        alignItems="start"
                        gap="10px"
                        display={isMobileOrTablet ? "none" : "flex"}
                      >
                        <img src={IMAGES.infoIcon} alt="" />
                        <Text
                          m={0}
                          fontFamily="K2D"
                          fontWeight={500}
                          fontSize="15px"
                          lineHeight="27px"
                          width="350px"
                          color={COLOR.black}
                        >
                          <span>
                            <TruncatedText
                              text={`${profile.position} ${profile.fullName} ${profile?.desc}` || ""}
                              maxLength={80}
                            />
                          </span>
                        </Text>
                      </Flex>
                    </Stack>
                  </Stack>
                  <Divider
                    m={isMobile || isTablet ? "10px 0" : "auto"}
                    height={isMobile || isTablet ? "2px" : "60%"}
                    background={COLOR.divider}
                    width={isMobile || isTablet ? "100%" : "2px"}
                  />
                  <Stack
                    alignItems="start"
                    justifyContent="center"
                    p="0 10px 0 5px"
                    gap="10px"
                  >
                    <style>{vibrateAnimation}</style>
                    <a
                      href="https://phongthuydainam.vn/lien-he/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Stack position="relative" cursor="pointer">
                        <img
                          style={{ width: "250px" }}
                          src={IMAGES.contactBtt}
                          alt=""
                        />
                        <Text
                          m={0}
                          _hover={{ color: COLOR.input }}
                          position="absolute"
                          top="50%"
                          left="50%"
                          transform="translate(-50%,-50%)"
                          fontFamily="K2D"
                          fontWeight={800}
                          fontSize={
                            isMobile
                              ? "14px"
                              : isTabletOrLaptop
                              ? "21px"
                              : "22px"
                          }
                          textAlign="center"
                          color={COLOR.black}
                          textTransform="uppercase"
                          animation={`${colorChange} 5s linear infinite`}
                          width="100%"
                        >
                          Đăng ký tư vấn
                        </Text>
                      </Stack>
                    </a>
                  </Stack>
                </Stack>
              </>
            </Flex>
          </a>
        </>
      </Stack>
    </>
  );
};

export default ProfilesCard;
