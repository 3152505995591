import { Flex, FlexProps, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import ScrollableTextContainer from "../SrollText";
import ContainerAllProfiles from "../ContainerAllProfiles";

type Props = {} & FlexProps;

export type CategoryType = {
  title: string;
  bg: string;
  description: string;
  reviewer: string;
};

const starts = [
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
];
const data = [
  {
    title: "01 - Chuyên môn cao",
    desc: "Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam đều được đào tạo và trải qua các kỳ thi sát hạch kiến thức - kỹ năng chuyên môn hàng năm.",
  },
  {
    title: "02 - kiến thức phong phú",
    desc: "Thầy/Chuyên gia Phong Thủy Đại Nam đều am hiểu sâu rộng về các kiến thức phong thủy cổ học ứng dụng khoa học hiện đại trong tư vấn, bao gồm các yếu tố như ngũ hành, âm dương, hướng nhà, bố trí nội thất,...với nhiều năm kinh nghiệm tư vấn và thiết kế phong thủy cho các công trình nhà ở, văn phòng, doanh nghiệp, khu du lịch,...",
  },
  {
    title: "03 - kinh nghiệm thực tiễn",
    desc: "Thầy/Chuyên gia Phong Thủy Đại Nam luôn ứng dụng linh hoạt kiến thức phong thủy vào thực tế để mang lại những giải pháp phù hợp với gia chủ.",
  },
  {
    title: "04 - tâm huyết & đạo đức",
    desc: "Nguyên tắc quan trọng hàng đầu của Phong Thủy Đại Nam là bảo mật thông tin khách hàng và luôn đặt lợi ích của khách hàng lên đầu với tiêu chuẩn: đạo đức nghề nghiệp làm tôn chỉ, làm việc với tinh thần trách nhiệm cao và thái độ chuyên nghiệp.",
  },
  {
    title: "05 - TẬN TÂM VỚI KHÁCH HÀNG",
    desc: "Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam luôn lắng nghe, thấu hiểu và chia sẻ vấn đề cùng gia chủ, chủ doanh nghiệp. Thầy/Chuyên gia luôn đồng hành cùng khách hàng trong suốt quá trình thực hiện hồ sơ và sau khi hoàn thiện theo quy trình và tiêu chuẩn chăm sóc của Phong Thủy Đại Nam.",
  },
];
const InfoSwiper = ({ ...props }: Props) => {
  const { isMobile, isMobileOrTablet, isTabletOrLaptop, isBigScreen } =
    useResponsive();

  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <ContainerAllProfiles mb={isTabletOrLaptop ? "70px" : "30px"}>
      <Flex
        {...props}
        justifyContent="center"
        alignItems="center"
        w="100%"
        position="relative"
      >
        <Swiper
          style={{
            width: "100%",
            maxWidth: isMobile ? "95vw" : "95vw",
          }}
          loop={true}
          autoplay={{ delay: 7000 }}
          grabCursor={true}
          slidesPerView={isMobile ? 1 : isTabletOrLaptop ? 2 : 3}
          slidesPerGroup={isMobile ? 1 : isTabletOrLaptop ? 2 : 2}
          spaceBetween={30}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
            renderBullet: (index: number, className: string) => {
              return `<span class="${className} custom-bullet"></span>`;
            },
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <Stack
                position="relative"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={IMAGES.bgReason}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
                <Stack
                  spacing={0}
                  position="absolute"
                  p={isTabletOrLaptop ? "55px 30px 30px 30px" : "30px 40px"}
                  gap="15px"
                  alignContent="space-between"
                  height="100%"
                  justifyContent="space-between"
                >
                  <Stack
                    spacing={0}
                    sx={{
                      backgroundImage: `url(${IMAGES.reasonSmall})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      p: "30px",
                    }}
                  >
                    <Text
                      m={0}
                      fontFamily="K2D"
                      fontSize={
                        isMobile
                          ? "15px"
                          : isMobileOrTablet
                          ? "18px"
                          : isBigScreen
                          ? "16px"
                          : "19px"
                      }
                      lineHeight={isMobileOrTablet ? "30px" : "38px"}
                      fontWeight={800}
                      color={COLOR.input}
                      textAlign="center"
                      textTransform="uppercase"
                    >
                      {item.title}
                    </Text>
                  </Stack>
                  <ScrollableTextContainer height="500px" isMobile={isMobile}>
                    <Text
                      m={0}
                      textAlign="justify"
                      fontFamily="K2D"
                      fontSize={
                        isMobile ? "17px" : isMobileOrTablet ? "18px" : "18px"
                      }
                      lineHeight="35px"
                      fontWeight={600}
                      color={COLOR.black}
                    >
                      {item.desc}
                    </Text>
                  </ScrollableTextContainer>
                </Stack>
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="custom-pagination"
          style={{
            position: "absolute",
            bottom: "-50px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
          }}
        ></div>
      </Flex>
      <style>
        {`
          .swiper-pagination-bullet.custom-bullet {
            width: 40px; 
            height: 4px; 
            margin: 0 5px;
            background-color:#B17636 ; 
            border-radius: 0; 
            position: relative;
          }

          .swiper-pagination-bullet.custom-bullet-active {
            background-color: #B17636; 
          }
        `}
      </style>
    </ContainerAllProfiles>
  );
};

export default InfoSwiper;
