import styled from "styled-components";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import { Text } from "@chakra-ui/react";

export const TextWellcome = styled.span`
  font-family: K2D;
  font-size: 24px;
  font-weight: 400;
  line-height: 39.84px;
  letter-spacing: -0.011em;
  text-align: justified;
  color: ${COLOR.descProfile};
  @media screen and (max-width: 575px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const TextTitle = styled.span`
  font-family: K2D;
  font-size: 32px;
  margin: 10px 0;
  font-weight: 600;
  line-height: 41.6px;
  text-align: left;
  color: ${COLOR.black};
    @media screen and (max-width: 900px) {
    font-size: 30px;
    line-height: 36.6px;
  }
  @media screen and (max-width: 575px) {
    font-size: 24px;
    width: 280px;
    line-height: 30.6px;
  }
`;
export const TextFilter = styled.span`
  font-family: K2D;
  font-size: 26px;
  font-weight: 600;
  line-height: 33.8px;
  text-align: left;
  color: ${COLOR.whiteBold};
  cursor: pointer;
`;
export const TextSpecialize = styled(Text)`
  font-family: K2D;
  font-size: 22px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: #1a1a1a;
`;
export const TextSelectedPosition = styled(Text)`
  font-family: K2D;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #161616;
`;
export const TextInfo = styled.span`
  font-family: K2D;
  font-size: 24px;
  font-weight: 700;
  line-height: 45.6px;
  letter-spacing: -0.011em;
  text-align: left;
  color: #161616;
  @media screen and (max-width: 900px) {
    font-size: 22px;
    line-height: 30.6px;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 30.6px;
  }
`;
