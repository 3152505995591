"use client";
import { useEffect, useState } from "react";
import React from "react";
import APP_ROUTES from "../../constant/CONFIG_ROUTES/app_routes";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import { Link, useLocation } from "react-router-dom";
import { Container, TextBreadcrumbs, WrapperBreadcrumbs } from "./style";
type dataLink = {
  name: string;
  link: string;
};
const dataPathName: dataLink[] = [
  {
    name: "Hệ Thống Chi Nhánh Đại Nam",
    link: APP_ROUTES.BRANCHLIST,
  },
  {
    name: "Đội ngũ Thầy/Chuyên gia Phong Thủy Đại Nam",
    link: APP_ROUTES.ALL,
  },
];

const BreadcrumbsPage = () => {
  const [pathnames, setPathnames] = useState<string[]>([]);
  const [array, setArray] = useState<dataLink[]>(dataPathName);
  const location = useLocation();

  useEffect(() => {
    getCurentPathName();
  }, [location]);
  const getCurentPathName = () => {
    const currentPathnames = location.pathname.split("/").filter((x) => x);
    setPathnames(currentPathnames);
  };

  const getPathName = (url: any) => {
    const pathnames = dataPathName.filter((i) => i.link === url);
    return pathnames[0]?.name;
  };
  return (
    <WrapperBreadcrumbs>
      <Container>
        <Link style={{ color: COLOR.input }} to={APP_ROUTES.ALL}>
          <TextBreadcrumbs>Trang chủ </TextBreadcrumbs>
        </Link>
        {pathnames.map((_, index) => {
          const url = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <TextBreadcrumbs
              style={{
                color: COLOR.black,
                opacity: 1,
              }}
              key={url}
            >
              / {getPathName(url)}
            </TextBreadcrumbs>
          ) : (
            <Link to={url} key={url}>
              <TextBreadcrumbs style={{ color: "#72130F" }}>
                / {getPathName(url)}
              </TextBreadcrumbs>
            </Link>
          );
        })}
      </Container>
    </WrapperBreadcrumbs>
  );
};
export default BreadcrumbsPage;
