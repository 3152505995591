import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactDOM from "react-dom/client";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Footer from "./component/Footer";
import Navbar from "./component/Navbar";
import APP_ROUTES from "./constant/CONFIG_ROUTES/app_routes";
import "./index.css";
import Home from "./pages/Home";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import AllProFile from "./pages/AllProfile";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import FooterAllProfiles from "./component/FooterAllProfiles";
import BranchList from "./pages/BranchList";
import { ThemeProvider } from "styled-components";

if (window.location.pathname === "/") {
  window.location.href = APP_ROUTES.ALL;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

ReactGA.initialize("G-HM6XBHJE12");

const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return null;
};

const App = () => {
  const [isProfile, setISProfile] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const arrRouter = Object.values(APP_ROUTES);
    if (arrRouter.includes(location.pathname)) {
      setISProfile(false);
    } else {
      setISProfile(true);
    }
  }, []);
  return (
    <>
      <TrackPageView />
      {isProfile ? null : <Navbar />}
      <Routes>
        <Route path={`${APP_ROUTES.HOME}/:id`} element={<Home />} />
        <Route path={APP_ROUTES.ALL} element={<AllProFile />} />
        <Route path={APP_ROUTES.BRANCHLIST} element={<BranchList />} />
      </Routes>
      {/*{isProfile ? null : <FooterAllProfiles />}*/}
    </>
  );
};

root.render(
  <ChakraProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </ChakraProvider>
);

reportWebVitals();
