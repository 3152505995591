import { useBreakpointValue } from "@chakra-ui/react";

const useResponsive = () => {
  const isSmallDevice = useBreakpointValue({
    xs: true,
    sm: false,
    base: false,
  });

  const isMobile = useBreakpointValue({
    xs: true,
    sm: true,
    md: false,
    lg: false,
    base: true,
  });
  const isTablet = useBreakpointValue({
    xs: false,
    sm: true,
    md: true,
    lg: false,
    base: true,
  });
  const isDesktop = useBreakpointValue({
    xs: false,
    sm: false,
    md: false,
    lg: true,
    base: true,
  });

  const isMobileOrTablet = useBreakpointValue({
    lg: false,
    base: true,
  });

  const isTabletOrLaptop = useBreakpointValue({
    xl: false,
    base: true,
  });

  const isBigScreen = useBreakpointValue({
    "2xl": false,
    base: true,
  });
  const isSamsungZFold5 = useBreakpointValue({
    xs: false,
    sm: true,
    md: false,
    base: true,
  });

  return {
    isSmallDevice,
    isMobile,
    isMobileOrTablet,
    isTabletOrLaptop,
    isBigScreen,
    isSamsungZFold5,
    isTablet,
    isDesktop
  };
};

export default useResponsive;
