import styled from "styled-components";
import IMAGES from "../../../constant/CONFIG_ROUTES/images";
import COLOR from "../../../constant/CONFIG_ROUTES/color";
import { Text } from "@chakra-ui/react";

export const Wrapper = styled.div`
  background-image: url(${IMAGES.bgTop});
  background-repeat: no-repeat;
  background-size: 100% 85%;
  width: 100%;
  .fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
  @media screen and (max-width: 575px) {
    background-size: 100% 55%;
  }
`;
export const TextBranchName = styled.span`
  font-family: K2D;
  font-size: 26px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  color: ${COLOR.text};
  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`;
export const TextContact = styled(Text)`
  font-family: K2D;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: ${COLOR.textDeco};
  cursor: pointer;
  &:hover {
    color: ${COLOR.hover};
  }
  @media screen and (max-width: 900px) {
    font-size: 16px;
  }
  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
`;
