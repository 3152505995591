import styled from "styled-components";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import COLOR from "../../constant/CONFIG_ROUTES/color";

export const Wrapper = styled.div`
  background-image: url(${IMAGES.bgFooter});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
`;
export const TextDefault = styled.span`
  font-family: K2D;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  wordbreak: break-word;
  color: ${COLOR.textDeco};
  &:hover {
    color: ${COLOR.hover};
  }
  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
`;
