const IMAGES = {
  logoXHero: "/xhero/logo_XheroZone.png",
  logoDaiNam: "/xhero/LogoDaiNam.png",
  bgInfo: "/xhero/bgInfo.png",
  bgInfoMobile: "/xhero/bgInfoMobile.png",
  bgContain: "/xhero/bgContain.png",
  bgTop: "/xhero/bgTop.png",
  bgTopp: "/xhero/bgTopp.png",
  bgTopMb: "/xhero/bgTopMb.png",
  s2ProfilesMb: "/xhero/s2ProfilesMb.png",
  profile: "/xhero/HinhProfile.png",
  phongthuy: "/xhero/phongthuy.png",
  titleTop: "/xhero/TitleTop.png",
  mailIcon: "/xhero/mailicon.svg",
  check: "/xhero/check.svg",
  bgButton: "/xhero/bgButton.png",
  iconPhone: "/xhero/iconPhone.png",
  iconZalo: "/xhero/iconZalo.png",
  logoLoading: "/xhero/LogoLoading.png",
  phoneIcon: "/xhero/phoneIcon.svg",
  locationIcon: "/xhero/locationIcon.svg",
  contain2: "/xhero/container2.png",
  webIcon: "/xhero/webIcon.svg",
  cityIcon: "/xhero/cityIcon.svg",
  arrow: "/xhero/circle-arrow.svg",
  taxIcon: "/xhero/taxIcon.svg",
  bgFooter: "/xhero/bgFooter.png",
  QR: "/xhero/imgQRCode.jpg",
  name: "/xhero/name.svg",
  review1: "/xhero/review1.png",
  review2: "/xhero/review2.png",
  s2Mb: "/xhero/s2Mb.png",
  swiperMobile: "/xhero/swiperMb.png",
  dotInactive: "/xhero/donInactive.png",
  dotActive: "/xhero/dotActive.png",
  idea: "/xhero/idea.png",
  cloudBot: "/xhero/cloudBot.png",
  bgAvt: "/xhero/bgAvt.png",
  bgCardProfile: "/xhero/bgCardProfile.png",
  bgCardProfileMb: "/xhero/bgCardProfileMb.png",
  icon1: "/xhero/icon1.svg",
  icon2: "/xhero/icon2.svg",
  dcma: "/xhero/dcma.png",
  congthuong: "/xhero/congthuong.png",
  bgFooter1: "/xhero/bgFooter1.png",
  bgFooter2: "/xhero/bgFooter2.png",
  bgFooter3: "/xhero/bgFooter3.png",
  facebookIcon: "/xhero/facebookIcon.svg",
  youtubeIcon: "/xhero/youtubeIcon.svg",
  zaloIcon: "/xhero/zaloIcon.svg",
  locationFooter: "/xhero/locationFooter.svg",
  logoFooter: "/xhero/logoFooter.png",
  qrFooter: "/xhero/qrFooter.jpg",
  tiktokIcon: "/xhero/tiktokIcon.svg",
  menu: "/xhero/menu.svg",
  bgBranchRight: "/xhero/bgRight.png",
  announcement: "/xhero/announcement.svg",
  AS: "/xhero/AS.png",
  ggP: "/xhero/ggP.png",
  headphone: "/xhero/headphone.svg",
  bgNav: "/xhero/bgNav.jpg",
  bgNavMb: "/xhero/bgNavMb.png",
  bgAll: "/xhero/bgAll.png",
  imageDemo: "../xhero/imageDemo.png",
  bgBranchItem: "../xhero/bgBranchItem.png",
  bgReason: "/xhero/bgReason.png",
  reasonBig: "/xhero/reasonBig.png",
  reasonSmall: "/xhero/reasonSmall.png",
  iconContact: "/xhero/iconContact.png",
  contactIcon: "/xhero/contactIcon.svg",
  textCard: "/xhero/textCard.png",
  back: "/xhero/back.png",
  next: "/xhero/next.png",
  business: "/xhero/business.svg",
  maps: "/xhero/maps.svg",
  hamburger: "/xhero/hamburger.svg",
  bgCertificate: "/xhero/bgCertificate.png",
  certificateActive: "/xhero/certificateActive.png",
  certificateUnactive: "/xhero/certificateUnactive.png",
  certificateUnactive2: "/xhero/certificateUnactive2.png",
  profileCard: "/xhero/profileCard.png",
  bgFilter: "/xhero/filter.png",
  searchIcon: "/xhero/searchIcon.svg",
  expandIcon: "/xhero/expandIcon.svg",
  decreaseIcon: "/xhero/decreaseIcon.svg",
  lineAll: "xhero/line.png",
  goldMedal: "/xhero/goldMedal.png",
  silverMedal: "/xhero/silverMedal.png",
  lineCertificate: "/xhero/lineCertificate.png",
  line2Certificate: "/xhero/line2Certificate.png",
  lineActive: "/xhero/lineActive.png",
  lineUnactive: "/xhero/lineUnactive.png",
  bgCertificateMb: "/xhero/bgCertificateMb.png",
  profileTextCard: "/xhero/profileTextCard.png",
  rating: "/xhero/rating.svg",
  bgProfiles: "/xhero/bgProfiles.jpg",
  titleProfiles: "/xhero/titleProfiles.png",
  s2Profiles: "/xhero/s2Profiles.png",
  s3Profiles: "/xhero/s3Profiles.png",
  nextPagination: "/xhero/nextPagination.png",
  prevPagination: "/xhero/prevPagination.png",
  iconSearch: "/xhero/IconSearch.png",
  contactBtt: "/xhero/contactBtt.png",
  infoIcon: "/xhero/infoIcon.svg",
  PLocationIcon: "/xhero/PLocationIcon.svg",
  pMailIcon: "/xhero/pMailIcon.svg",
  pPhoneIcon: "/xhero/pPhoneIcon.svg",
  branchPhone: "/xhero/iconPhoneBranch.png",
  branchPlace: "/xhero/iconPlace.png",
  buttonBranch: "/xhero/ButtonBranch.png",
  ButtonBg: "/xhero/ButtonBg.png",
  imgBranch: "/xhero/imgBranch.png",
  iconSupport: "/xhero/iconSupport.png",
  iconFeedback: "/xhero/iconFeedback.png",
  iconShield: "/xhero/iconShield.png",
  iconGuaranteed: "/xhero/iconGuaranteed.png",
  bgBranchHover: "/xhero/bgBranchHover.png",
  iconPhoneHover: "/xhero/iconPhoneHover.png",
  iconPlaceHover: "/xhero/iconPlaceHover.png",
  bgPopup: "/xhero/bgPopup.png",
  iconCalender: "/xhero/calendar.png",
  iconMarket: "/xhero/iconMarket.png",
  imageMap: "/xhero/imgMap.png",
  QRCodeApp: "/xhero/QRCodeApp.jpg"
};

export default IMAGES;
