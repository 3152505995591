import { Flex, FlexProps, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import COLOR from "../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../hooks/useResponsive";
import Container from "../Container";
import ScrollableTextContainer from "../SrollText";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {} & FlexProps;

export type CategoryType = {
  title: string;
  bg: string;
  description: string;
  reviewer: string;
};

const starts = [
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
  {
    img: IMAGES.rating,
  },
];

const SwiperReview = (itemCard: any, { ...props }: Props) => {
  const { isMobile, isTabletOrLaptop, isMobileOrTablet } = useResponsive();
  const reviews = itemCard?.itemCard?.reviews || [];

  React.useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (!reviews.length) {
    return null;
  }

  return (
    <Container mb={isTabletOrLaptop ? "70px" : "30px"}>
      <Flex
        {...props}
        justifyContent="center"
        alignItems="center"
        w="100%"
        position="relative"
      >
        <Swiper
          style={{
            width: "100%",
            maxWidth: isMobile ? "95vw" : "90vw",
          }}
          loop={true}
          autoplay={{ delay: 5000 }}
          grabCursor={true}
          slidesPerView={ isMobileOrTablet ? 1 : 2}
          slidesPerGroup={isMobileOrTablet ? 1 : 2}
          spaceBetween={30}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
            renderBullet: (index: number, className: string) => {
              return `<span class="${className} custom-bullet"></span>`;
            },
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {reviews.map((item: any, index: any) => (
            <SwiperSlide key={index}>
              <Stack
                style={{
                  marginTop:
                    index % 2 === 0 ? (isTabletOrLaptop ? 0 : "5px") : 0,
                }}
                position="relative"
                justifyContent="center"
                alignItems="center"
              >
                <LazyLoadImage
                  src={
                    index % 2 === 0
                      ? isMobile
                        ? IMAGES.swiperMobile
                        : IMAGES.review1
                      : IMAGES.review2
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: isMobile ? "45vh" : "100%",
                  }}
                />
                <Stack
                  spacing={0}
                  position="absolute"
                  p={isTabletOrLaptop ? "55px 30px 30px 30px" : "30px 40px"}
                  gap="15px"
                  alignContent="space-between"
                  height="100%"
                  justifyContent="space-between"
                >
                  <ScrollableTextContainer height="500px" isMobile={isMobile}>
                    <Text
                      m={0}
                      textAlign="justify"
                      fontFamily="K2D"
                      fontSize="18px"
                      lineHeight="23px"
                      fontWeight={600}
                      sx={{
                        background:
                          "linear-gradient(#e4bd61,#ffdf5a, #feedb5,#ffd055,#ffb500)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      {item.comment}
                    </Text>
                  </ScrollableTextContainer>

                  <Stack
                    justifyContent="flex-end"
                    marginBottom={index % 2 === 0 ? "30px" : "20px"}
                  >
                    <Stack w="full" h="2px" bg={COLOR.lineInfo} />
                    <Flex gap="5px" justifyContent="flex-end">
                      {starts.map((starItem, starIndex) => (
                        <Flex
                          alignItems="center"
                          gap="20px"
                          key={starIndex}
                          flexWrap="wrap"
                        >
                          <LazyLoadImage
                            loading="lazy"
                            style={{
                              width: "20px",
                            }}
                            src={starItem.img}
                            alt=""
                          />
                        </Flex>
                      ))}
                    </Flex>
                    <Text
                      m={0}
                      textAlign="end"
                      fontFamily="K2D"
                      fontSize="18px"
                      lineHeight="23px"
                      color={COLOR.white}
                      fontWeight={700}
                    >
                      {item.name}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="custom-pagination"
          style={{
            position: "absolute",
            bottom: "-50px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
          }}
        ></div>
      </Flex>
      <style>
        {`
          .swiper-pagination-bullet.custom-bullet {
            width: 14px; 
            height: 14px; 
            margin: 0 5px;
            position: relative;
            border: 2px solid  #FFD055; 
            border-radius: 50%; 
            background: transparent; 
          }

          .swiper-pagination-bullet.custom-bullet::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6px; 
            height: 6px; 
            border-radius: 50%; 
            background-color:  #FFD055; 
          }
        `}
      </style>
    </Container>
  );
};

export default SwiperReview;
