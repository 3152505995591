import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  @media screen and (max-width: 1200px) {
    padding: 0 10px;

  }
  @media screen and (max-width: 575px) {
    padding: 0 10px;
    margin-top: 40px;
  }
`;

export const WrapperBreadcrumbs = styled.div`
  width: 100%;
  background: #fdf9f0;
  padding: 8px 0;
  @media screen and (max-width: 900px) {
    // margin-top: 20%;
  }
  @media screen and (max-width: 575px) {
    margin-top: 15%;
  }
`;
export const TextBreadcrumbs = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: left;
  @media screen and (max-width: 575px) {
    opacity: 0.7;
  }
`;