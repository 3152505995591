import { Flex, Stack, Text } from "@chakra-ui/react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Container from "../../../component/Container";
import COLOR from "../../../constant/CONFIG_ROUTES/color";
import IMAGES from "../../../constant/CONFIG_ROUTES/images";
import useResponsive from "../../../hooks/useResponsive";
import ScrollableTextContainer from "../../../component/SrollText";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TextDecs, TextInfoThay, TextTitle, Wrapper } from "./style";
const Section2 = (itemCard: any) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const {
    isMobile,
    isTablet,
    isSamsungZFold5,
    isMobileOrTablet,
    isTabletOrLaptop,
  } = useResponsive();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  return (
    <>
      <Container direction="column">
        <Wrapper>
          <Stack
            maxW={isTabletOrLaptop ? "800px" : "1109px"}
            w="full"
            p="20px"
            height="100%"
            mt={isMobile ? "40px" : "120px"}
            mb="20px"
            gap="20px"
          >
            <TextTitle>Chuyên môn:</TextTitle>
            <Stack>
              <Stack gap="15px">
                {itemCard?.itemCard?.majors.map((item: string, index: any) => (
                  <Flex alignItems="center" gap="20px" key={index}>
                    <img width={24} height={24} src={IMAGES.arrow} alt="" />
                    <TextDecs
                      transition="all 235ms ease-in-out"
                      cursor="pointer"
                      _hover={{
                        transform: "translate3d(0, 4px, 0)",
                        color: COLOR.hover,
                      }}
                      m={0}
                    >
                      {item}
                    </TextDecs>
                  </Flex>
                ))}
              </Stack>
            </Stack>
            <Flex gap="10px" direction={isMobileOrTablet ? "column" : "row"}>
              <TextTitle>Quá trình làm việc:</TextTitle>
              <Flex alignItems="center" gap="20px">
                <img
                  width={24}
                  height={24}
                  style={{ display: isMobileOrTablet ? "flex" : "none" }}
                  src={IMAGES.arrow}
                  alt=""
                />
                <TextTitle>
                  {itemCard?.itemCard?.exp}+{" "}
                  <TextTitle style={{ fontWeight: 400 }}>Năm</TextTitle>
                </TextTitle>
              </Flex>
            </Flex>
            <Flex gap="10px" direction={isMobileOrTablet ? "column" : "row"}>
              <TextTitle>Thành tựu:</TextTitle>

              <Flex alignItems="center" gap="20px">
                <img
                  width={24}
                  height={24}
                  style={{ display: isMobileOrTablet ? "flex" : "none" }}
                  src={IMAGES.arrow}
                  alt=""
                />
                <TextTitle>
                  {itemCard?.itemCard?.achievement}+{" "}
                  <TextTitle style={{ fontWeight: 400 }}> Hồ sơ</TextTitle>
                </TextTitle>
              </Flex>
            </Flex>

            <Stack
              style={{ overflow: "hidden" }}
              height={isMobile || isTablet ? "350px" : "auto"}
            >
              <ScrollableTextContainer
                height={isMobile ? "300px" : "100%"}
                isMobile={isMobile}
              >
                <TextInfoThay>
                  {itemCard.itemCard.position}{" "}
                  <TextInfoThay style={{ fontWeight: 800 }}>
                    {itemCard?.itemCard?.fullName}
                  </TextInfoThay>{" "}
                  {itemCard?.itemCard?.desc}
                </TextInfoThay>
              </ScrollableTextContainer>
            </Stack>
          </Stack>
        </Wrapper>
      </Container>
    </>
  );
};

export default Section2;
